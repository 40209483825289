<template>
<Loader v-if="isLoading" />
<div class="bg-gray-100 flex items-start justify-center min-h-screen bg-fixed">
    <div class=" container mx-auto h-full overflow-hidden">
        <div class>
            <div class="container flex flex-col items-center justify-between w-full h-full mt-10 ">
                <div class="form-login mt-20">
                    <h1 class="font-bold-segoe text-3xl text-center text-white mt-[36%] mb-[34px]">
                        Acesse Seu Autoconhecimento
                    </h1>
                    <!-- <button @click="teste('teste teste teste')">teste</button> -->
                    <LoginForm @show-modal="showModal" @loading="setLoading" />
                    <forgot-password />
                    <div class="flex flex-col items-center">
                        <img src="@/assets/icons/hooy/hooy-cut-white.svg" alt="" class="w-[100px] mt-[15%]">
                        <img src="@/assets/logos/voceautentico-branco.png" alt="" class="mt-[10%]">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ModalAlert v-model="show" title="Atenção" @confirm="confirm">
    <p v-html="contentModal"></p>
</ModalAlert>


</template>

<script setup>
import ModalAlert from '@/components/Modals/ModalAlert.vue';
import LoginForm from '@/modules/login/components/LoginForm.vue';
import Loader from '@/modules/login/components/Loader.vue';
import { ref } from 'vue';
import ForgotPassword from '../components/ForgotPassword.vue';

const show = ref(false);
const contentModal = ref('');
const isLoading = ref(false);
const confirm = () => {
    show.value = false;
};

const teste = (message) => {
    contentModal.value = message;
    show.value = true;
};

const showModal = (message) => {
    contentModal.value = message;
    show.value = true;
};

const setLoading = (loading) => {
    isLoading.value = loading;
};
</script>

<style scoped>
.container {
    max-width: 100%;
}

.bg-fixed {
    background-image: url('../../../assets/login-bg-1080-1920-b.png');
    background-size: cover;
    background-position: bottom;
    height: 100%;
}

.form-login {
    z-index: 5;
}

.background-login {
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
}

.background-image {
    background-image: url('@/assets/login-bg-1080-1920.png');
    background-size: cover;
    background-position: center;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 5;
    margin-top: 150px;
    object-fit: cover;
    object-position: center;
}
</style>
