<template>
  <div class="flex flex-col items-center justify-center mx-[27px] mb-[20px] gap-2" v-tour-step:5="steps[4]">
    <router-link v-for="acao in acoes" :key="acao._id" :to="'/acao/' + acao._id"
      class="flex items-center justify-center font-bold-segoe bg-[#F37335] rounded-md text-[#FFF] text-[14px] py-[5px] px-[10px] w-[90%]">
      <img :src="getIcon(acao.difficulty)" class="mr-4" alt="">
      <p class="mx-2 text-center w-[80%]">{{ acao.textContent }}</p>
    </router-link>
    <router-link
      class="flex items-center justify-between font-bold-segoe bg-[#FFF212] outline-none border-none rounded-md text-[#F37335] text-[14px] py-[5px] px-[10px] w-[90%]"
      to="/fatos" v-tour-step:4="steps[3]">
      <img src="@/assets/icons/avatars/fato.svg" alt="" class="mr-4">
      <p class="mx-2 text-center w-[80%]">Personalize a sua ação</p>
    </router-link>
  </div>
</template>

<script setup>
import { defineProps, inject } from 'vue';
import nivel1 from '@/assets/icons/utilities/nivel1.svg';
import nivel2 from '@/assets/icons/utilities/nivel2.svg';
import nivel3 from '@/assets/icons/utilities/nivel3.svg';
import createTourSteps from "@/utilities/custom-tour/actionTour/action-tour";

const actionTour = inject("actionTour");
const steps = createTourSteps(actionTour);

const props = defineProps({
  acoes: {
    type: Array,
    required: true,
  },
});

const getIcon = (difficulty) => {
  switch (difficulty) {
    case 'beginner':
      return nivel1;
    case 'intermediary':
      return nivel2;
    case 'advanced':
      return nivel3;
    default:
      return '';
  }
};
</script>

<style scoped></style>
