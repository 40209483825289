<template>
<div class="content mt-[29px]">
    <h1 class="font-bold-segoe text-center text-2xl text-[#f34b21] leading-none mx-[58px]">
        Refletir sobre um fato é entender como ele moldou quem você é hoje.
    </h1>
    <div class="logo-step flex items-center justify-center my-4">
        <img src="@/assets/icons/avatars/step-one.png" alt="">
        <img src="@/assets/icons/numbers/1.svg" alt="">
    </div>
    <h2 class="font-bold-segoe text-[#f34b21] text-[23px] text-center uppercase">Passo 1</h2>
    <p class="text-center font-bold-segoe text-[#f34b21] mb-5">
        Descreva o acontecido de forma breve.
    </p>
    <p class="font-italic-segoe text-[13px] text-[#727376] text-center mx-[58px]">
        Refletir sobre um fato é entender como ele moldou quem você é hoje.
    </p>
    <div class="flex flex-col items-center justify-center mt-[25px]">
        <button
            class="flex items-center justify-center font-bold-segoe m-[25px] text-[13px] text-[#f34b21] border-[#f34b21] border-2 p-3 rounded-2xl w-[80%] mx-[50px]"
            @click="criarAcao()">
            <img src="@/assets/icons/utilities/more.svg" class="mr-2"> Adicione o acontecimento
        </button>
    </div>
</div>
<ModalInput v-model="show" title="Faça uma breve descrição do fato ocorrido" @create="() => create()"
    @back="() => back()" :color="'#f34b21'">
    <FormKit v-model="textContent" type="textarea" name="questao" :classes="config_textarea" cols=50 rows=2 />
</ModalInput>
</template>
<script setup>
    import { ref, defineEmits } from 'vue';
    import { useRouter } from 'vue-router';
    import ModalInput from '@/components/Modals/ModalInput.vue';
    import { useQuestionResponse } from '@/store/index';
    import { config_textarea } from '@/modules/acao/utilities/config_form';

    const emit = defineEmits(['loading']);
    const router = useRouter();
    const store = useQuestionResponse();
    const textContent = ref('');
    const show = ref(false);

    // async function create() {
    //     if (textContent.value) {
    //         emit('loading', true);

    //         console.log('textContent.value', textContent.value)

    //         await store.requestFato(textContent.value)
    //             .then((res) => {
    //                 console.log('res', res)
    //                 console.log('store.acao', store.acao)
    //                 console.log('store.acao._id', store.acao._id)
    //                 router.push(`/acao/${store.acao._id}`)
    //             })
    //             .finally(() => {
    //                 emit('loading', false);
    //             })
    //     }
    // }

    async function create() {
        if (textContent.value) {

            emit('loading', true);

            try {
                const res = await store.requestFato(textContent.value);
                console.log('res', res);
                console.log('store.acao', store.acao);
                console.log('store.acao._id', store.acao._id);
                router.push(`/acao/${store.acao._id}`);
            }
            catch (error) {
                console.error('Erro ao criar ação', error);
            }
            finally {
                emit('loading', false);
            }
        }
    }
    function back() {
        show.value = false;
    }

    async function criarAcao() {
        show.value = true;
    }



</script>
<style></style>
