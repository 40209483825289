<script setup>
import { useRouter } from 'vue-router';
import { defineProps, computed } from 'vue';

import arrowOrange from '@/assets/icons/arrows/arrow-left-orange.svg';
import arrowDarkBlue from '@/assets/icons/arrows/arrow-left-darkblue.svg';
import arrowDarkGreen from '@/assets/icons/arrows/arrow-left-darkgreen.svg';
import arrowGreen from '@/assets/icons/arrows/arrow-left-green.svg';
import arrowRed from '@/assets/icons/arrows/arrow-left-red.svg';
import arrowDDBlue from '@/assets/icons/arrows/arrow-left-ddblue.svg';

import hooyOrange from '@/assets/icons/hooy/hooy-cut-orange.svg'
import hooyBlue from '@/assets/icons/hooy/hooy-cut-blue.svg'
import hooyDarkBlue from '@/assets/icons/hooy/hooy-cut-darkblue.svg'
import hooyDarkGreen from '@/assets/icons/hooy/hooy-cut-darkgreen.svg'
import hooyGreen from '@/assets/icons/hooy/hooy-cut-green.svg'
import hooyRed from '@/assets/icons/hooy/hooy-cut-red.svg'
import hooyWhite from '@/assets/icons/hooy/hooy-cut-white.svg'
import hooyDDBlue from '@/assets/icons/hooy/hooy-cut-ddblue.svg'

const props = defineProps([ 'iconVoltar', 'miniLogo' ])

const urlArrow = computed(() => {
  switch (props.iconVoltar) {
    case 'orange':
      return arrowOrange;
    case 'darkblue':
      return arrowDarkBlue;
    case 'ddblue':
      return arrowDDBlue;
    case 'darkgreen':
      return arrowDarkGreen;
    case 'green':
      return arrowGreen;
    case 'red':
      return arrowRed;
    default:
      return false;
  }
});

const urlHooy = computed(() => {
  switch (props.miniLogo) {
    case 'orange':
      return hooyOrange;
    case 'blue':
      return hooyBlue;
    case 'darkblue':
      return hooyDarkBlue;
    case 'ddblue':
      return hooyDDBlue;
    case 'darkgreen':
      return hooyDarkGreen;
    case 'green':
      return hooyGreen;
    case 'red':
      return hooyRed;
    case 'white':
      return hooyWhite;
    default:
      return false;
  }
});


const router = useRouter();
const redirect = () => {
    console.log('filho');
  if (window.history.state && window.history.length > 1) {
    router.back();
  } else {
    router.push('/');
  }
};



</script>
<template>
  <footer class="pb-[40px]">
    <div class="flex items-center justify-between mx-[27px]">
      <a @click="redirect" class="cursor-pointer" v-if="urlArrow">
        <img :src="urlArrow" alt="" id="hooy-cut" class="mx-[1px] w-[50px]">
      </a>
      <img :src="urlHooy" alt="" id="hooy-cut" class="mx-[1px] w-[80px]">
    </div>
  </footer>
</template>
