<template>
  <div class="mx-[28px] flex flex-col items-center justify-center">
    <div class="flex flex-col items-center justify-center">
      <h1 class="text-[#0080C5] text-[26px] text-center font-bold-segoe leading-6 my-3">
        Oba, parabéns!
      </h1>
      <img src="@/assets/icons/avatars/frequencia.png" alt="" class="my-2">
      <h4 class="text-[#0080C5] text-[15px] text-center font-bold-segoe leading-6 mx-[60px] mb-2">
        Acredite, esse compromisso vai
        contribuir com o seu processo
        de autodesenvolvimento.
      </h4>
    </div>
    <div class="w-[100%] flex flex-col gap-2 items-center justify-center my-5">
      <div class="w-[90%] bg-[#21DDFF] rounded-[12px] p-[0.5rem] text-white text-center text-[14px] font-bold-segoe">
        <h5 class="text-[26px]">Dica Autêntica</h5>
        Comprometer-se com o que é possível para
        você, sem alta exigência, é importante para a
        construção de novos hábitos.
      </div>
      <p class="text-[#727376] text-[13px] text-center font-italic-segoe mx-[60px] my-4">
        Você pode voltar a atualizar esse
        compromisso sempre que quiser.
      </p>
      <div
        class="w-[90%] bg-[#00A9E2] rounded-[12px] p-[0.7rem] text-white text-center text-[14px] font-bold-segoe my-4 cursor-pointer"
        @click="redirecionar('/progresso')">
        seguir em frente
      </div>
    </div>
  </div>
</template>

<script setup>
import { useCompromisso } from '@/modules/compromisso/composables/useCompromisso';

const { redirecionar } = useCompromisso();
</script>

<style scoped></style>
