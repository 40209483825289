export const config_textarea = {
  input:
    "w-[90%] m-3 bg-[#FEEEE1] rounded-lg border border-[#f34b21]  outline-none p-2 placeholder-[#f34b21] placeholder-[8px] placeholder:text-[11px] placeholder:italic text-[#F37334]",
  inner: "flex items-center justify-center",
};
export const config_submit = {
  input:
    "w-[90%] flex items-center justify-center font-bold-segoe bg-[#f34b21] outline-none border-none rounded-md text-[#FFF] text-[14px] p-[15px] my-2 mx-[27px] w-[80%] mb-[30px]",
  wrapper: "flex items-center justify-center",
};
