<template>
<div class="mx-7 flex flex-col items-center justify-center">
    <div class="flex flex-col items-center justify-center">
        <h1 class="text-[#2F8E87] text-[26px] text-center font-bold-segoe leading-6 my-5">
            Ciclos Educacionais
        </h1>
    </div>
    <div class="flex flex-wrap justify-center items-center mt-10 gap-4 relative">
        <router-link to="/ciclos-educacionais/emocoes">
            <div class="h-[150px] w-[150px] flex justify-center items-center rounded-2xl bg-[#47C0B7]">
                <span class="font-bold-segoe text-[20px]  text-white uppercase">Emoções</span>
            </div>
        </router-link>
        <!-- <router-link to="/ciclos-educacionais/presenca">
        <div class="h-[150px] w-[150px] flex justify-center items-center rounded-2xl bg-[#41ADA4]">
          <span class="font-bold-segoe text-[20px] text-white uppercase">Presença</span>
        </div>
      </router-link> -->
        <div class="h-[150px] w-[150px] flex justify-center items-center rounded-2xl bg-[#359D94] cursor-not-allowed">
            <div class="flex flex-col justify-center items-center">
                <img src="@/assets/lock.png" alt="cadeado" class="mb-2">
                <span class="font-bold-segoe text-[20px] text-white uppercase">Presença</span>
            </div>
        </div>
        <!-- <router-link to="/ciclos-educacionais/resiliencia"> -->
        <div class="h-[150px] w-[150px] flex justify-center items-center rounded-2xl bg-[#359D94] cursor-not-allowed">
            <div class="flex flex-col justify-center items-center">
                <img src="@/assets/lock.png" alt="cadeado" class="mb-2">
                <span class="font-bold-segoe text-[20px] text-white uppercase">Resiliência</span>
            </div>
        </div>
        <!-- </router-link> -->
        <!-- <router-link to="/ciclos-educacionais/felicidade"> -->
        <div class="h-[150px] w-[150px] flex justify-center items-center rounded-2xl bg-[#2F8E87] cursor-not-allowed">
            <div class="flex flex-col justify-center items-center">
                <img src="@/assets/lock.png" alt="cadeado" class="mb-2">
                <span class="font-bold-segoe text-[20px] text-white uppercase">Felicidade</span>
            </div>
        </div>
        <!-- </router-link> -->
        <div class="absolute h-[280px] w-[280px] bg-white opacity-10 rounded-full pointer-events-none"></div>
    </div>
</div>
</template>

<script setup>

</script>
