<script setup>
import { ref, inject, onMounted } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const config_textarea = {
  input:
    "w-[90%] m-3 bg-[#DDF6F4] rounded-lg border border-[#37A79F]  outline-none p-2 placeholder-[#37A79F] placeholder-[8px] placeholder:text-[11px] placeholder:italic text-[#37A79F]",
  inner: "flex items-center justify-center",
};
const config_submit = {
  input:
    "w-[90%] flex items-center justify-center font-bold-segoe bg-[#37A79F] outline-none border-none rounded-md text-[#FFF] text-[14px] p-[15px] my-2 w-[80%] mb-[3px]",
  wrapper: "flex items-center justify-center",
};

const axios = inject('axios');
const questions = ref([]);
const currentQuestion = ref("");
const currentQuestionId = ref(null);
const response = ref("");
let previousIndex = null;

const getAsk = async () => {
  try {
    const response = await axios.get('/ask');
    questions.value = response.data.ask;
    getRandomQuestion();
  } catch (error) {
    console.log('ERROR: ', error);
  }
}

const getRandomQuestion = () => {
  if (questions.value.length) {
    let randomIndex;
    do {
      randomIndex = Math.floor(Math.random() * questions.value.length);
    } while (randomIndex === previousIndex);

    currentQuestion.value = questions.value[randomIndex].textContent;
    currentQuestionId.value = questions.value[randomIndex]._id;
    previousIndex = randomIndex;
  }
}

const randomAsk = () => {
  getRandomQuestion();
}

const submitReflection = async () => {
  try {
    await axios.post('/ask', {
      questionId: currentQuestionId.value,
      questionText: currentQuestion.value,
      response: response.value
    })
      .then((response) => {
        console.log('response: ', response)
        router.push('/progresso')
      })
    response.value = ""; // Limpar a resposta após enviar
  } catch (error) {
    console.log('ERROR: ', error);
  }
}

onMounted(() => {
  getAsk();
});
</script>

<template>
  <h5 class="font-bold-segoe text-center text-[15px] text-[#37A79F] leading-none mx-[58px] my-8">
    Leia com atenção e <br />
    reflita sua resposta.
  </h5>
  <div class="flex justify-center items-center mx-[1rem] my-12 ">
    <h1 id="ask" class="text-center font-bold-segoe text-[26px] text-[#37A79F] h-50 w-30 text-wrap mx-8">
      {{ currentQuestion }}
    </h1>
  </div>
  <!-- Textarea -->
  <FormKit type="textarea" name="questao" placeholder="Sugestão: Faça em tópicos." :classes="config_textarea"
    v-model="response" :value="response" cols=50 rows=5 />
  <!-- Botão -->
  <FormKit type="button" :classes="config_submit" label="outra pergunta" @click="randomAsk" />
  <FormKit type="submit" :classes="config_submit" label="enviar reflexão" @click="submitReflection" />
</template>

<style scoped></style>
