<script setup>
import { ref } from 'vue';
import Navbar from '@/components/Navbar/Navbar.vue';
import BannerInicial from '@/components/Banners/BannerInicial.vue';
import Footer from '@/components/Footers/FooterInicial.vue';
import Loader from '@/modules/acao/components/Loader.vue';

const orange = 'orange';
</script>

<template>
  <div class="flex flex-col min-h-screen">
    <header>
      <Navbar />
      <BannerInicial :logoHooy="orange" :borderColor="orange" />
    </header>
    <main class="flex-grow">
      <router-view />
    </main>
    <footer class="mt-auto">
      <Footer :iconVoltar="orange" :miniLogo="orange" />
    </footer>
  </div>
</template>
