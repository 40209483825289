import { inject } from "vue";
import { useRouter } from "vue-router";

export const useCompromisso = () => {
    const axios = inject("axios");
    const router = useRouter();

    const setFrequence = async (value) => {
        const body = {
            frequence: parseInt(value),
        };
        try {
            const userBaseUrl = `${process.env.VUE_APP_API_URL}/user`;
            const { data } = await axios.post(`${userBaseUrl}/frequencia`, body);
            if (data.success) {
                console.log("RETORNO API: ", data.msg);
                router.push("/compromisso-ok");
            }
        } catch (error) {
            console.log("ERROR no CATCH: ", error);
        }
    };

    const redirecionar = (path) => {
        router.push(path);
    };

    return {
        setFrequence,
        redirecionar,
    };
};
