<script setup>
    import { inject, reactive, defineEmits, defineProps } from 'vue';
    import { useRouter } from 'vue-router';
    import { vMaska } from 'maska';
    import { useForm } from '@/modules/cadastro/composables/useForm';

    const axios = inject('axios');
    const router = useRouter();
    const { styleConfig, checboxConfig, maskedValue, option, antes, hoje, handleIconClick } = useForm();

    const form = reactive({});
    const emit = defineEmits(['loading']);

    const props = defineProps({
        trial: Boolean,
        trialDays: Number
    });

    const register = async (dados) => {
        form.whatsapp = maskedValue.value.unmasked;
        const user = {
            name: form.name,
            birthday: form.birthday,
            gender: form.gender,
            cel: form.whatsapp,
            email: form.email.email_confirm,
            password: form.password.password_confirm,
            policy: form.policy,
            newsletter: form.newsletter
        };

        if (props && props.trial) {
            user.approved = true;
            user.trial = true;
            user.trialDays = props.trialDays;
        }

        try {
            emit('loading', true);  // Emit event to start loader
            const userBaseUrl = `${process.env.VUE_APP_API_URL}/user`;
            const res = await axios.post(`${userBaseUrl}/cadastrar`, user);

            if (res.data.success === true) {
                localStorage.setItem('token', res.data.token);
                router.push('/aguarde');
            }
        }
        catch (error) {
            console.error('Xiii catch do try: ', error);
        }
        finally {
            emit('loading', false);  // Emit event to stop loader
        }
    }
</script>
<template>
<div class="flex flex-col justify-around items-center">

    <h1 class="font-bold-segoe text-3xl text-center text-[#68A74E] mt-[70px] mb-[34px]">Faça o seu Cadastro</h1>


    <FormKit type="form" :actions="false" @submit="register" message-class="text-center mx-[25px] text-red-400"
        incomplete-message="Desculpe, alguns campos não foram preenchidos corretamente." v-model="form">

        <FormKit type="text" name="name" id="name" label="Nome Completo" validation="required"
            validation-visibility="live" validation-label="Nome Completo"
            :validation-messages="{ required: 'Nome completo é obrigatório' }" :classes="styleConfig" />

        <FormKit type="date" name="birthday" id="birthday" label="Data de Nascimento"
            :validation="[['date_between', antes, hoje]]" :min="antes" :max="hoje" validation-visibility="live"
            validation-label="Data de Nascimento"
            :validation-messages="{ required: 'Data é obrigatório', date_between: `A data deve ser entre os anos ${antes.getUTCFullYear()} e ${hoje.getUTCFullYear()}.` }"
            :classes="styleConfig" />

        <FormKit type="select" name="gender" id="gender" label="Gênero" validation="required"
            validation-visibility="live" :validation-messages="{ required: 'Gênero é obrigatório' }"
            :classes="styleConfig"
            :options="[{ label: 'Selecione um gênero', attrs: { disabled: true } }, { label: 'Masculino', value: 'male' }, { label: 'Feminino', value: 'female' }, { label: 'Prefiro não informar', value: 'other' }]" />

        <FormKit type="tel" name="whatsapp" id="whatsapp" label="Celular | Whatsapp" validation="required"
            validation-visibility="live" validation-label="Celular"
            :validation-messages="{ required: 'Celular é obrigatório' }" :classes="styleConfig"
            v-maska:[option]="maskedValue" placeholder="(DD) 99999-9999" />

        <!-- E-MAIL's -->
        <FormKit type="group" name="email">
            <FormKit type="email" name="email" id="email" label="Email" validation="required|email"
                validation-visibility="live" validation-label="Nome Completo"
                :validation-messages="{ required: 'Email é obrigatório', email: 'Insira um e-mail válido' }"
                :classes="styleConfig" />

            <FormKit type="email" name="email_confirm" id="email_confirm" label="Confirme seu email"
                validation="required|confirm" validation-visibility="live" validation-label="Confirmation"
                :validation-messages="{ required: 'Confirmação é obrigatório', email: 'Insira um e-mail válido', confirm: 'Email não confere' }"
                :classes="styleConfig" />
        </FormKit>

        <!-- SENHAS -->
        <FormKit type="group" name="password">
            <FormKit type="password" name="password" id="password" label="Senha" validation="required|password"
                validation-visibility="live" validation-label="Senha"
                :validation-messages="{ required: 'Senha é obrigatório' }" suffix-icon="eyeClosed"
                @suffix-icon-click="handleIconClick" suffixIcon-class="icon" :classes="styleConfig" />

            <FormKit type="password" name="password_confirm" id="password_confirm" label="Confirme sua senha"
                validation="required|confirm" validation-visibility="live" validation-label="Confirmation"
                :validation-messages="{ required: 'Confirmação é obrigatório', confirm: 'Senha não confere' }"
                suffix-icon="eyeClosed" suffixIcon-class="icon" @suffix-icon-click="handleIconClick"
                :classes="styleConfig" />
        </FormKit>

        <FormKit type="checkbox" label="Aceito receber promoções e material de marketing pelos canais de comunicação."
            name="newsletter" id="newsletter" :classes="checboxConfig" />

        <FormKit type="checkbox" label="Declaro que conheço a política de privacidade e a lei de proteção de dados."
            name="policy" id="policy"
            help="A sua privacidade é a nossa prioridade, todas as respostas são criptografadas e somente você pode ter acesso."
            validation="accepted" validation-visibility="live"
            :validation-messages="{ accepted: 'Para continuar é necessário aceitar nossa Política de Privacidade.' }"
            :classes="checboxConfig" />

        <FormKit type="submit" label="Cadastrar" name="submit" id="submit" :classes="{
            wrapper: 'flex flex-col mx-[32px]',
            input: 'font-bold-segoe  bg-[#8BC756] outline-none border-none rounded-md text-[#FFF] text-[14px] p-[15px] my-8',
        }" />

    </FormKit>

</div>
</template>
<style>

    /* Estilos para o botão desabilitado */
    .disabled-style {
        background-color: #D1D2D0;
    }

    .icon>svg {
        width: 20px;
        height: 20px;
        color: #68A74E;
    }
</style>
