export default function (initialTour) {
    return [
        {
            tour: initialTour,
            options: {
                title: "Introdução",
                text: "Veja a introdução que preparamos para você, <strong> a plataforma Hooy </strong> é fácil e segura de usar.",
                attachTo: { on: "" },
                classes: "initial-tour",
                buttons: [
                    {
                        text: "pular",
                        action: initialTour.cancel,
                    },
                    {
                        text: "iniciar",
                        action: initialTour.next,
                    },
                ],
            },
        },
    ];
}

//
