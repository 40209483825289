<template>
	<Header :logo="logoGreenAg" :border="borderGreenAg" class="relative z-10" />
	<div class="container h-100">
		<div class="content h-50 flex flex-col items-center justify-start">
			<img src="@/assets/icons/utilities/verify.svg" alt="verificado" class="w-[67px] h-[66px] mt-[64px]">
			<h1 class="font-bold-segoe text-white text-4xl mt-5">Cadastro Finalizado</h1>
			<p class="w-80 text-center mt-6 text-white font-normal text-xl">
				Recebemos seus dados, obrigado!
				Em até 24 horas você receberá a confirmação
				de acesso pelo <strong>Whatsapp</strong>.
			</p>
		</div>
		<div class="mt-[116px] pb-[50px] flex flex-col items-center justify-around h-50">
			<router-link to="/">
				<button class="bg-[#8BC756] text-white font-bold-segoe px-[46px] py-[8px] rounded-full">
					Voltar para o Início
				</button>
			</router-link>
			<img src="@/assets/icons/hooy/hooy-cut-white.svg" alt="simbolo hooy" class="mt-[116px] h-[59px] w-[99px]">
			<img src="@/assets/logos/voceautentico-branco.png" alt="logo Você Autentico" class="mt-[87px]">
		</div>

	</div>
</template>

<script setup>
import Header from '@/components/Header/Header.vue'
import LogoGreen from '@/assets/logos/logo-green.svg';
import BorderGreen from '@/assets/borders/border-header-green.svg'

const logoGreenAg = 'only-green';
const borderGreenAg = 'green-ag'
</script>

<style scoped>
.container {
	background-image: url('@/assets/background-aguarde.svg');
	background-repeat: no-repeat;
	background-size: cover;
}
</style>
