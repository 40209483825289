import { ref, reactive } from "vue";

export function useForm() {
  const styleConfig = reactive({
    wrapper: "flex flex-col mx-[32px]",
    message: "text-[12px] text-red-400 mx-[32px]",
    label: "font-italic-segoe text-sm text-[#727376]",
    input: "font-bold-segoe p-[10px] text-[#68A74E] outline-none w-80",
    outer: "flex flex-col mb-[10px]",
    inner: "flex flex-row border-b-2 border-[#68A74E] mb-1 items-center",
  });

  const checboxConfig = reactive({
    wrapper: "flex mx-[32px] my-[25px]",
    message: "text-[12px] text-red-400 mx-[32px]",
    inner: "mr-4 flex items-center justify-center",
    label: "font-italic-segoe text-sm text-[#727376]",
    help: "text-[12px] mx-[20px] mb-[15px]",
    decorator: "border-[#68A74E]",
  });

  const maskedValue = ref({});
  const option = {
    mask: "(##) #####-####",
  };

  const antes = new Date("1924-01-01");
  const hoje = new Date();

  const handleIconClick = (node, e) => {
    node.props.suffixIcon =
      node.props.suffixIcon === "eye" ? "eyeClosed" : "eye";
    node.props.type = node.props.type === "password" ? "text" : "password";
  };

  return {
    styleConfig,
    checboxConfig,
    maskedValue,
    option,
    antes,
    hoje,
    handleIconClick,
  };
}
