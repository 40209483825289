<script setup>
import Navbar from '@/components/Navbar/Navbar.vue';
import BannerInicial from '@/components/Banners/BannerInicial.vue';
import Footer from '@/components/Footers/FooterInicial.vue';

const blue = 'blue';

</script>
<template>
  <div class="flex flex-col min-h-screen">
    <header>
      <Navbar />
      <BannerInicial :logoHooy="blue" :borderColor="blue" />
    </header>
    <main class="flex-grow">
      <router-view />
    </main>
    <footer class="mx-auto">
      <Footer :iconVoltar="blue" :miniLogo="blue" />
    </footer>
  </div>
</template>
