<template>
  <div class="mx-[28px]" v-if="loading">
    <div class="text-center my-4">
      <h1 class="font-bold-segoe text-[#FD7862] text-[26px] mb-3 leading-7">Diário de <br />ações de bem-estar</h1>
      <p class="font-italic-segoe text-[13px] text-[#727376] text-center mx-[15px]">
        O diário de reflexões é uma excelente oportunidade para você perceber sua capacidade de pensar por si mesmo.
        Além disso, ele ajuda a aprimorar suas habilidades de expressão, tornando-o mais articulado e influente.
      </p>
    </div>
    <div class=" flex flex-col items-center justify-center">
      <div v-for="questao in fatos" :key="questao.idAction" class="mb-2">
        <router-link v-if="questao.difficulty === 'custom'" :to="'/historico/' + questao.idAction">
          <div class="w-80 bg-[#FED2CD] border-[#FD7062] border rounded-[10px] p-2 text-center">
            <p class="text-[#FD5445] text-sm font-bold-segoe">{{ questao.textAction }}</p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { usePerfil } from '@/modules/progresso/composables/usePerfil';

const { fatos, loading, fetchPerfil } = usePerfil();
console.log('fatos: ', fatos)

onMounted(() => {
  fetchPerfil();
});
</script>

<style scoped></style>
