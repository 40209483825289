<template>
<div v-if="success">
    <div>
        <div id="titulo" class="leading-none mx-[28px] ">
            <h1 class="font-bold-segoe text-center text-[26px] text-[#f34b21] uppercase mb-5">
                Ciclo do Autodesenvolvimento
            </h1>
            <h4 class="font-bold-segoe text-[15px] text-center text-[#f34b21]">
                <span v-if="questions[0].difficulty !== 'custom'">AÇÃO:</span>
                <span v-else>FATO:</span>
                {{ questions[0].textAction }}
            </h4>
        </div>
        <div class="mx-[28px]">
            <div class="text-center mb-[30px]">
                <p
                    class="text-[#f34b21] font-bold-segoe bg-[#FFF688] border border-[#f34b21] rounded-[20px] p-2 text-center mt-4">
                    APRENDI : {{ questions[0].responseAction[3].response.slice(0, 50) }}
                    <span v-if="questions[0].responseAction[3].response.length > 50"
                        class="text-[12px] font-italic-segoe font-extralight cursor-pointer text-black"
                        @click="openModal(3)">
                        Ver mais...
                    </span>
                </p>
                <span class="text-sm text-[#f34b21] font-bold-segoe">Respondida no dia: {{ createdAt }} </span>
            </div>
            <div id="reflexao" class="flex flex-col items-end justify-center">
                <div id="pensei"
                    class="flex w-[220px] min-h-16 items-center justify-end mb-5 bg-[#F3B421] border border-[#FFF212] rounded-[20px] text-white font-bold-segoe p-2">
                    <div class="w-screen text-center">
                        <p>
                            PENSEI:
                            {{ questions[0].responseAction[0].response.slice(0, 50) }}
                            <span v-if="questions[0].responseAction[0].response.length > 50"
                                class="text-[12px] font-italic-segoe font-extralight cursor-pointer text-black"
                                @click="openModal(0)">
                                Ver mais...
                            </span>
                        </p>
                    </div>
                </div>
                <div id="senti"
                    class="flex w-[220px] min-h-16 items-center justify-end mb-5 bg-[#F39121] border border-[#FFF212] rounded-[20px] text-white font-bold-segoe p-2">
                    <div class="w-screen text-center">
                        <p>
                            SENTI:
                            {{ questions[0].responseAction[1].response.slice(0, 50) }}
                            <span v-if="questions[0].responseAction[1].response.length > 50"
                                class="text-[12px] font-italic-segoe font-extralight cursor-pointer text-black"
                                @click="openModal(1)">
                                Ver mais...
                            </span>
                        </p>
                    </div>
                </div>
                <div id="reagi"
                    class="flex w-[220px] min-h-16 items-center justify-end mb-5 bg-[#F34B21] border border-[#FFF212] rounded-[20px] text-white font-bold-segoe p-2">
                    <div class="w-screen text-center">
                        <p>
                            REAGI:
                            {{ questions[0].responseAction[2].response.slice(0, 50) }}
                            <span v-if="questions[0].responseAction[2].response.length > 50"
                                class="text-[12px] font-italic-segoe font-extralight cursor-pointer text-black"
                                @click="openModal(2)">
                                Ver mais...
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div>
                <button
                    class="formkit-input flex items-center justify-center font-bold-segoe bg-[#f34b21] outline-none border-none rounded-md text-[#FFF] text-[14px] p-[10px] my-2 w-[100%] mb-[30px]"
                    @click="navegarParaProgresso">
                    próximo
                </button>
            </div>
        </div>
    </div>
</div>
<ModalAlert v-model="show" title="" @confirm="confirm" @click-outside="clickOutside">
    <p v-html="contentModal" class="font-bold-segoe text-[#f34b21] text-center"></p>
</ModalAlert>
</template>

<script setup>
import { ref, onMounted, defineProps, defineEmits, inject } from 'vue';
import ModalAlert from '@/components/Modals/ModalAlert.vue';
import { useRouter } from 'vue-router'

const emit = defineEmits(['loading']);

const props = defineProps({
    id: {
        type: String,
        required: true,
    },
});

const router = useRouter();
const success = ref(false);
const questions = ref([]);
const createdAt = ref('');
const axios = inject('axios');
const show = ref(false);
const contentModal = ref('');

function confirm() {
    show.value = false;
}

const openModal = (i) => {
    show.value = true;

    if (questions.value.length > 0 && questions.value[0].responseAction) {
        contentModal.value = questions.value[0].responseAction[i].response;
    }
    else {
        console.error('Erro: questions[0] ou questions[0].responseAction não estão definidos.');
    }
};

const clickOutside = () => {
    confirm();
};

const navegarParaProgresso = () => {
    router.push({ name: 'inicio-progresso' })
};

onMounted(async () => {
    emit('loading', true);
    try {
        const userBaseUrl = `${process.env.VUE_APP_API_URL}/user`;
        const { data } = await axios.get(`${userBaseUrl}/historico/${props.id}`);
        console.log('DATA: ', data);
        if (data.success) {
            questions.value = data.questions;
            createdAt.value = data.createdAt;
            success.value = true;
        }
        else {
            console.log('Erro ao carregar os dados da API.');
        }
    }
    catch (error) {
        console.error('Erro na requisição à API:', error);
    }
    finally {
        emit('loading', false);
    }
});
</script>

<style scoped>
#reflexao {
    background-image: url('@/assets/icons/avatars/boneco-acao.svg');
    background-repeat: no-repeat;
    height: 300px;
}
</style>
