<template><router-view /></template>
<script setup>
import { ref } from 'vue';
import "@/utilities/custom-tour/style/global.css"
// Config Modal
const show = ref(false);

function confirm() {
    show.value = false
    const newWindow = window.open(window.location.href, '_blank', 'width=430,height=900');
    if (newWindow) {
        newWindow.focus();
    }
}
// Verifica a largura da tela ao carregar o componente
window.addEventListener('load', () => {
    show.value = window.innerWidth > 430;
});


</script>
<style>
body {
    width: 100%;
}
</style>
