<template>
<div class="content mt-[29px]" v-if="!loading"> <!-- !loading para exibir conteúdo quando os dados estiverem prontos -->
    <div>
        <!-- Texto da Questão -->
        <h1 v-show="store.responseQuestion.length < 3" v-tour-step:6="steps[5]"
            class="font-bold-segoe text-center text-[26px] text-[#F37335] leading-none mx-[58px]">
            {{ acao.textContent }}
        </h1>
        <router-view></router-view>
    </div>
</div>
</template>

<script setup>
    import { defineProps, ref, inject, onMounted } from 'vue';
    import { useQuestionResponse } from '@/store/index';
    import createTourSteps from "@/utilities/custom-tour/actionTour/action-tour";

    const store = useQuestionResponse();
    const actionTour = inject("actionTour");
    const steps = createTourSteps(actionTour);

    const props = defineProps(['id']);
    const loading = ref(true); // loading começa como true para exibir o indicador de carregamento
    const acao = ref({});

    async function buscarAcao(id) {
        try {
            console.log('Questao view');
            console.log('ID: ', id);
            await store.requestAcao(id); // Aguardar o retorno da ação
            acao.value = store.acao; // Atualiza o valor de acao com os dados recebidos
        } catch (error) {
            console.error('Erro ao buscar ação:', error);
        } finally {
            loading.value = false; // Desativa o loading ao finalizar a operação
        }
    }

    // Usando onMounted para garantir que a busca da ação aconteça quando o componente for montado
    onMounted(() => {
        if (props.id) {
            buscarAcao(props.id);
        }
    });
</script>

<style></style>
