<template>
<CustomModal v-model="showPrivacyPolicyModal" title="Hooy. Respeito a sua privacidade." @confirm="confirm()"
    modalClass="flex justify-center items-center bg-[rgba(0,0,0,0.5)]"
    contentClass="text-[#727376] bg-[#FEEEE2] border border-[#F37334] flex flex-col max-w-xl m-4 p-4 rounded-lg space-y-2"
    titleClass="text-[#F37334] font-bold text-center mb-4 text-xl font-bold-segoe">
    <p class="text-center">
        As suas <strong>respostas</strong>, informações e
        dados pessoais, <strong>são criptografadas</strong>
        apenas você consegue visualizar.
    </p>
</CustomModal>

<Loader :loading="loading" />
<div class="flex flex-col min-h-screen" v-tour-step:1="steps[0]">
    <header>
        <Navbar />
        <BannerInicial :logoHooy="color" :borderColor="color" />
    </header>
    <main class="flex-grow">
        <router-view @loading="handleLoader" />
    </main>
    <footer class="mt-auto">
        <Footer :iconVoltar="color" :miniLogo="color" />
    </footer>
</div>
</template>
<script setup>
    import { ref, onBeforeMount, inject, watch, onMounted, computed } from 'vue';
    import Navbar from '@/components/Navbar/Navbar.vue';
    import BannerInicial from '@/components/Banners/BannerInicial.vue';
    import Footer from '@/components/Footers/FooterInicial.vue';
    import Loader from '@/modules/acao/components/Loader.vue';
    import CustomModal from '@/components/Modals/CustomModal.vue';
    import createTourSteps from "@/utilities/custom-tour/initialTour/initial-tour";
    import { useStartupStore } from "@/stores/startup.store";
    import { useAuthStore } from "@/store";
    import { useRouter } from 'vue-router'

    const startupStore = useStartupStore();
    const auth = useAuthStore();
    const initialTour = inject("initialTour");
    const steps = createTourSteps(initialTour);
    const showPrivacyPolicyModal = ref(false);
    const color = ref('orange');
    const loading = ref(false);
    const router = useRouter();
    const queryParams = ref(router.currentRoute.value.query);

    const handleLoader = () => {
        loading.value = !loading.value;
    };

    const confirm = () => {
        auth.setAcceptedPolicy(true);

        if (showPrivacyPolicyModal.value && !startupStore.tourInicialRealizado) {
            setTimeout(() => {
                initialTour.start();
            }, 2000);
        }
    };

    watch(
        () => auth.acceptedPolicy, (novoEstado, estadoAnterior) => {
            if (novoEstado) {
                showPrivacyPolicyModal.value = false;
            }
        }
    );

    watch(
        () => router.currentRoute.value.path, (novo, anterior) => {
            if (novo === '/fatos' || novo.includes('/acao/') || novo.includes('/historico/')) {
                color.value = 'red';
            }
            else {
                color.value = 'orange';
            }
        }
    );

    onBeforeMount(() => {

        if (router.currentRoute.value.path === '/fatos') {
            color.value = 'red';
        }
        else {
            color.value = 'orange';
        }
    });

    onMounted(() => {
        if (!auth.acceptedPolicy && !startupStore.tourInicialRealizado) {
            showPrivacyPolicyModal.value = true;
            initialTour.hide();
            return;
        }
    });

</script>
