<script setup>
import { defineProps, computed, watch } from 'vue';

import logoWhiteBgBlue from '@/assets/logos/logo-white-bg-blue.svg';
import logoWhiteBgDarkBlue from '@/assets/logos/logo-white-bg-darkblue.svg';
import logoWhiteBgDarkGreen from '@/assets/logos/logo-white-bg-darkgreen.svg';
import logoWhiteBgGreen from '@/assets/logos/logo-white-bg-green.svg';
import logoWhiteBgOrange from '@/assets/logos/logo-white-bg-orange.svg';
import logoWhiteBgRed from '@/assets/logos/logo-white-bg-red.svg';
import logoWhite from '@/assets/logos/logo-white.svg';
import logoGreen from '@/assets/logos/logo-green.svg';
import logoOrange from '@/assets/logos/logo-orange.svg';
import logoWhiteBgDDBlue from '@/assets/logos/logo-white-bg-ddblue.svg';


import borderHeaderDDBlue from '@/assets/borders/border-header-ddblue.svg';
import borderHeaderBlue from '@/assets/borders/border-header-blue.svg';
import borderHeaderDarkBlue from '@/assets/borders/border-header-darkblue.svg';
import borderHeaderDarkGreen from '@/assets/borders/border-header-darkgreen.svg';
import borderHeaderGreen from '@/assets/borders/border-header-green.svg';
import borderHeaderOrange from '@/assets/borders/border-header-orange.svg';
import borderHeaderRed from '@/assets/borders/border-header-red.svg';

const props = defineProps(['logoHooy', 'borderColor']);

const urlLogo = computed(() => {
  switch (props.logoHooy) {
    case 'orange':
      return logoWhiteBgOrange;
    case 'blue':
      return logoWhiteBgBlue;
    case 'darkblue':
      return logoWhiteBgDarkBlue;
    case 'ddblue':
      return logoWhiteBgDDBlue;
    case 'darkgreen':
      return logoWhiteBgDarkGreen;
    case 'green':
      return logoWhiteBgGreen;
    case 'red':
      return logoWhiteBgRed;
    case 'only-white':
      return logoWhite;
    case 'only-green':
      return logoGreen;
    case 'only-orange':
      return logoOrange;
    default:
      return '';
  }
});

const urlBorder = computed(() => {
  switch (props.borderColor) {
    case 'orange':
      return borderHeaderOrange;
    case 'blue':
      return borderHeaderBlue;
    case 'darkblue':
      return borderHeaderDarkBlue;
    case 'ddblue':
      return borderHeaderDDBlue;
    case 'darkgreen':
      return borderHeaderDarkGreen;
    case 'green':
      return borderHeaderGreen;
    case 'red':
      return borderHeaderRed;
    default:
      return '';
  }
});

watch(() => props.logoHooy, (newValue) => {
  console.log('logoHooy', newValue);
});

</script>
<template>
  <div>
    <img :src="urlLogo" class="w-screen mb-[-20px]" />
    <img :src="urlBorder" alt="" class="w-screen" />
  </div>
</template>
<style scoped>


</style>
