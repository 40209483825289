<template>
<div class="mx-7 flex flex-col items-center justify-center">
    <div class="flex flex-col items-center justify-center">
        <h1 class="text-[#2F8E87] text-[26px] text-center font-bold-segoe leading-6 my-5">
            {{ props.title }}
        </h1>
    </div>
    <div class="w-full">
        <div v-if="audios.length">
            <div v-for="(item, index) in audios" class="border-t-2 w-full flex gap-4 py-1 my-1" :key="index">
                <button @click="playAudio(index)" :id="`play-${index}`">
                    <play></play>
                </button>
                <button @click="pauseAudio(index)" :id="`pause-${index}`" class="hidden">
                    <pause></pause>
                </button>
                <audio ref="audioPlayers" :src="`${audioBaseSrc}${item.caminho}`" :id="`audio-${index}`"></audio>
                <span class="font-bold-segoe text-[#37A79F]"> {{ item.nome }}</span>
            </div>
        </div>
    </div>
</div>
</template>
<script setup>
import { ref, defineProps, onMounted } from 'vue';
import audioList from './data/audio-map.json';
import Play from '@/assets/icons/commands/Play.vue';
import Pause from '@/assets/icons/commands/Pause.vue';
// import Stop from '@/assets/icons/commands/Stop.vue';

const props = defineProps({
    audioType: String,
    title: String,
});

const audioBaseSrc = ref(process.env.VUE_APP_URL);
const audios = ref([]);
const audioPlayers = ref([]);

const loadAudioMap = async (audioType) => {
    const selectedAudioType = await audioList.categorias.filter(categoria => categoria.tipo === audioType);
    audios.value = selectedAudioType[0].audios;
}

const playAudio = (index) => {
    stopOthers(index);
    const audioElement = document.getElementById(`audio-${index}`);
    const playElement = document.getElementById(`play-${index}`);
    const pauseElement = document.getElementById(`pause-${index}`);

    if (audioElement) {
        audioElement.play();
        playElement.classList.add('hidden');
        pauseElement.classList.remove('hidden');
    }
};

const pauseAudio = (index) => {
    const audioElement = document.getElementById(`audio-${index}`);
    const playElement = document.getElementById(`play-${index}`);
    const pauseElement = document.getElementById(`pause-${index}`);

    if (audioElement) {
        audioElement.pause();
        playElement.classList.remove('hidden');
        pauseElement.classList.add('hidden');
    }
};

const stopOthers = (index) => {
    audioPlayers.value.forEach((audio, i) => {
        if (i !== index) {
            if (audio) {
                audio.pause();
                audio.currentTime = 0;
                const playElement = document.getElementById(`play-${i}`);
                const pauseElement = document.getElementById(`pause-${i}`);
                playElement.classList.remove('hidden');
                pauseElement.classList.add('hidden');
            }
        }
    });
};

onMounted(() => {
    loadAudioMap(props.audioType);
});

</script>
