<template>
  <div v-if="store.acao && store.responseQuestion.length !== 0">
    <!-- Texto -->
    <h1 class="font-bold-segoe text-center text-[26px] text-[#F37335] leading-none mx-[58px] uppercase mb-5">
      Ciclo do Autoconhecimento
    </h1>
    <!-- LISTA DE RESPOSTAS -->
    <div>
      <div class="grid-rows-4 grid-flow-col flex justify-center place-content-center items-center mx-[10px]">
        <img src="@/assets/icons/numbers/one-question.png" alt="">
        <div
          class="w-[90%] flex items-center justify-center font-bold-segoe bg-[#F3B421] outline-none border-none rounded-md text-[#FFF] text-[14px] p-[15px]">
          <p>
            {{ store.acao.textContent }}
          </p>
        </div>
      </div>
      <div class="grid-rows-4 grid-flow-col flex justify-center place-content-center items-center mx-[10px]">
        <img src="@/assets/icons/numbers/two-question.png" alt="">
        <div
          class="w-[90%] flex items-center justify-center font-bold-segoe bg-[#F39121] outline-none border-none rounded-md text-[#FFF] text-[14px] p-[15px]">
          <p>
            PENSEI: {{ store.responseQuestion[0].response }}
          </p>
        </div>
      </div>
      <div class="grid-rows-4 grid-flow-col flex justify-center place-content-center items-center mx-[10px]">
        <img src="@/assets/icons/numbers/three-question.png" alt="">
        <div
          class="w-[90%] flex items-center justify-center font-bold-segoe bg-[#F37335] outline-none border-none rounded-md text-[#FFF] text-[14px] p-[15px]">
          <p>
            SENTI: {{ store.responseQuestion[1].response }}
          </p>
        </div>
      </div>
      <div class="grid-rows-4 grid-flow-col flex justify-center place-content-center items-center mx-[10px]">
        <img src="@/assets/icons/numbers/four-question.png" alt="">
        <div
          class="w-[90%] flex items-center justify-center font-bold-segoe bg-[#F34B21] outline-none border-none rounded-md text-[#FFF] text-[14px] p-[15px]">
          <p>
            REAGI: {{ store.responseQuestion[2].response }}
          </p>
        </div>
      </div>
    </div>
    <!-- Texto -->
    <p class="text-center font-bold-segoe text-[#F37335] my-5 mx-[27px]">
      O que é possível aprender sobre você ao ler seu ciclo do autoconhecimento?
    </p>
    <!-- Textarea -->
    <FormKit type="textarea" name="questao"
      placeholder="Sugestão de início: lendo meus pensamentos, sentimentos e reações reconheço que..."
      :classes="config_textarea" cols=50 v-model="response" :value="response" rows=5 />
    <!-- Botão -->
    <FormKit type="submit" :classes="config_submit" label="concluir" @click="submitForm" />
  </div>

  <ModalAlert v-model="show" title="" @confirm="confirm" @click-outside="clickOutside">
    <p v-html="contentModal" class="font-bold-segoe text-[#F37335] text-center"></p>
  </ModalAlert>
</template>

<script setup>
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import ModalAlert from '@/components/Modals/ModalAlert.vue';
import { useQuestionForm } from '@/modules/acao/composables/useQuestionForm';
import { config_textarea, config_submit } from '@/modules/acao/utilities/config_form';
import { useQuestionResponse } from '@/store/index';

const { store } = useQuestionForm();
const router = useRouter();
const question = 'O que é possível aprender sobre você ao ler seu ciclo do autoconhecimento?';
const formId = 4;
const nextRoute = `/historico/${store.acao._id}`;

const { response, submitForm, show, contentModal } = useQuestionForm(question, formId, nextRoute, true);

const confirm = () => {
  show.value = false;
  router.push(nextRoute);
};

const clickOutside = () => {
  confirm();
};

onMounted(() => {
  const existingResponse = store.responseQuestion.find(
    (item) => item.id === formId
  );
  if (existingResponse) {
    response.value = existingResponse.response;
  }
});
</script>

<style></style>
