<template>
  <div class="mx-[28px]" v-if="loading">
    <div class="text-center my-4">
      <h1 class="font-bold-segoe text-[#FD7862] text-[26px] mb-3 leading-7">Diário de reflexões</h1>
      <p class="font-italic-segoe text-[13px] text-[#727376] text-center mx-[15px]">
        Aqui estão reunidas as suas pequenas e grandes histórias
        relatadas aqui no Hooy. Este diário te leva a reconhecer e a
        acreditar em sua evolução. Utilize sempre que precisar
        buscar referências sobre si.
      </p>
    </div>
    <div class=" flex flex-col items-center justify-center">
      <div v-for="ask in user.asktoGo" :key="ask.idAsk" class="mb-2">
        <div class="w-80 bg-[#FED2CD] border-[#FD7062] border rounded-[10px] p-2 text-center">
          <h1 class="text-[#FD5445] text-sm font-bold-segoe">{{ ask.textContent }}</h1>
          <p class="text-[#FD5445] text-sm">{{ ask.textAnswer }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { usePerfil } from '@/modules/progresso/composables/usePerfil';

const { user, loading, fetchPerfil } = usePerfil();

onMounted(() => {
  fetchPerfil();
});
</script>

<style scoped></style>
