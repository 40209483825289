const startupTour = (initialTour, menuClicked) => {
    return [
        {
            text: "<strong> Ajuda, veja as perguntas frequentes no nosso FAQ.</strong>",
            attachTo: { element: '#faq', on: "bottom" },
            classes: "initial-tour",
            highlightClass: '',
            buttons: [
                {
                    text: "pular",
                    action: initialTour.complete,
                },
                {
                    text: "próximo",
                    action: initialTour.next,
                },
            ]
        },
        {
            text: "<strong> Acesse seu perfil, atualize suas informações pessoais.</strong>",
            attachTo: { element: '#perfil', on: "bottom" },
            classes: "initial-tour",
            highlightClass: '',
            buttons: [
                {
                    text: "pular",
                    action: initialTour.complete,
                },
                {
                    text: "próximo",
                    action: initialTour.next,
                },
            ]
        },
        {
            text: "<strong> Menu, possibilita escolher o que deseja realizar.</strong>",
            attachTo: { element: '#menu', on: "bottom" },
            classes: "initial-tour",
            highlightClass: '',
            buttons: [
                {
                    text: "pular",
                    action: initialTour.complete,
                },
                {
                    text: "próximo",
                    action: initialTour.next,
                },
            ]
        },
        {
            text: "<strong> Encontre ações que ajudam a cultivar um estado de felicidade de forma orientada para o desenvolvimento do comportamento.</strong>",
            attachTo: {
                element: '#acao', on: "top",
                highlightClass: ''
            },
            classes: "startup-tour acao",
            highlightClass: '',
            when: {
                show: menuClicked
            },
            buttons: [
                {
                    text: "pular",
                    action: initialTour.complete,
                },
                {
                    text: "próximo",
                    action: initialTour.next,
                },
            ]
        },
        {
            text: "<strong> Registre eventos diários, positivos ou negativos, para explorar seus padrões comportamentais e sair do automático.</strong>",
            attachTo: { element: '#fatos', on: "top" },
            classes: "startup-tour fato",
            buttons: [
                {
                    text: "pular",
                    action: initialTour.complete,
                },
                {
                    text: "próximo",
                    action: initialTour.next,
                },
            ]
        },
        {
            text: "<strong> Baseado na fórmula da felicidade da psicologia positiva, ele permite avaliar seu bem-estar atual e acompanhar sua evolução ao longo do uso da plataforma.</strong>",
            attachTo: { element: '#teste', on: "top" },
            classes: "startup-tour teste",
            buttons: [
                {
                    text: "pular",
                    action: initialTour.complete,
                },
                {
                    text: "próximo",
                    action: initialTour.next,
                },
            ]
        },
        {
            text: "<strong> Encontre suporte imediato com o ciclo de respiração 6:3 para acalmar e reorientar suas emoções em momentos de crise.</strong>",
            attachTo: { element: '#sos', on: "top" },
            classes: "startup-tour sos",
            buttons: [
                {
                    text: "pular",
                    action: initialTour.complete,
                },
                {
                    text: "próximo",
                    action: initialTour.next,
                },
            ]
        },
        {
            text: "<strong>Explore quatro ciclos educativos de sete dias para aproveitar ao máximo o Hooy. Ouça áudios educacionais que facilitarão seu autoconhecimento.</strong>",
            attachTo: { element: '#ciclos', on: "top" },
            classes: "startup-tour ciclos",
            buttons: [
                {
                    text: "pular",
                    action: initialTour.complete,
                },
                {
                    text: "próximo",
                    action: initialTour.next,
                },
            ]
        },
        {
            text: "<strong>Desbloqueie momentos de paralisação ou dilemas com perguntas que desafiam a pensar por diferentes perspectivas, gerando insights e novas consciências.</strong>",
            attachTo: { element: '#perguntas', on: "top" },
            classes: "startup-tour perguntas",
            buttons: [
                {
                    text: "pular",
                    action: initialTour.complete,
                },
                {
                    text: "próximo",
                    action: initialTour.next,
                },
            ]
        },
        {
            text: "<strong>Acalme a mente, promovendo um ambiente de paz interior e conexão consigo mesmo. Um espaço dedicado à introspecção e cultivo do autoconhecimento.</strong>",
            attachTo: { element: '#silencio', on: "top" },
            classes: "startup-tour silencio",
            buttons: [
                {
                    text: "pular",
                    action: initialTour.complete,
                },
                {
                    text: "próximo",
                    action: initialTour.next,
                },
            ]
        },
        {
            text: "<strong>Faça um compromisso pessoal para praticar ações de bem-estar que promovem o autocuidado, bem-estar e o desenvolvimento pessoal.</strong>",
            attachTo: { element: '#compromisso', on: "top" },
            classes: "startup-tour compromisso",
            buttons: [
                {
                    text: "pular",
                    action: initialTour.complete,
                },
                {
                    text: "próximo",
                    action: initialTour.next,
                },
            ]
        },
        {
            text: "<strong>Através de 3 diários, observe sua própria evolução ao longo do tempo com base em informações reais, promovendo uma mudança de perspectiva consistente.</strong>",
            attachTo: { element: '#progresso', on: "top" },
            classes: "startup-tour progresso",
            buttons: [
                {
                    text: "pular",
                    action: initialTour.complete,
                },
                {
                    text: "próximo",
                    action: initialTour.next,
                },
            ]
        },
        {
            text: "<strong>Escolha sua ação</strong>",
            attachTo: { on: "" },
            classes: "initial-tour",
            buttons: [
                {
                    text: "OK",
                    action: initialTour.complete,
                },
            ]
        },
    ];
};

export default startupTour;
