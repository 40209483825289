<template>
<div id="navbar" class="w-[100%] flex items-center justify-between p-4">
    <!-- Ícone do menu dropdown à esquerda -->
    <img @click="menuClicked" id="menu" src="@/assets/icons/utilities/menu-dropdown.svg" alt="Menu"
        class="w-6 h-6 cursor-pointer" />
    <!-- Espaçador para alinhar as imagens à direita -->
    <div class="flex-grow"></div>
    <!-- Imagem de conta -->
    <router-link to="/perfil" id="perfil">
        <img src="@/assets/icons/avatars/user-account.svg" alt="Conta" class="w-6 h-6 mr-4 cursor-pointer" />
    </router-link>
    <help-button></help-button>
</div>
<Slide :width="menuWidthSize.toString()" noOverlay :burgerIcon="burgerIcon" :crossIcon="crossIcon" :isOpen="isOpen"
    @openMenu="isOpen = true" @closeMenu="isOpen = false" :closeOnNavigation="true" disableOutsideClick>
    <img src="@/assets/logos/logo-white-bg-orange.svg" class="w-screen" />
    <div class="h-[100%] flex flex-col items-center justify-center gap-4 mt-[10%] mb-[10%] text-center">
        <router-link id="acao" to="/acao"
            class=" w-[300px] p-[6px] bg-[#F36E21] rounded-[9px] font-bold-segoe text-white text-[20px] text-center">
            Ações de Bem-estar
        </router-link>
        <router-link id="compromisso" to="/compromisso"
            class=" w-[300px] p-[6px] bg-[#0080C5] rounded-[9px] font-bold-segoe text-white text-[20px] text-center">
            Compromisso
        </router-link>
        <router-link id="teste" to="/questionarios/permahm/perguntas"
            class=" w-[300px] p-[6px] bg-[#FDBE2D] rounded-[9px] font-bold-segoe text-white text-[20px] text-center">
            Teste seu Bem-estar
        </router-link>
        <!-- <router-link id="fatos" to="/fatos"
            class=" w-[300px] p-[6px] bg-[#F34B21] rounded-[9px] font-bold-segoe text-white text-[20px] text-center">
            Minhas Experiências
        </router-link> -->
        <a id="perguntas" href="#"
            class=" w-[300px] p-[6px] bg-[#F34B21] rounded-[9px] font-bold-segoe text-white text-[20px] text-center cursor-not-allowed">
            <LockClosedIcon class="text-center size-4 align-middle me-1" style="display: inline;" />
            Minhas Experiências
        </a>
        <a id="perguntas" href="#"
            class=" w-[300px] p-[6px] bg-[#2F8E87] rounded-[9px] font-bold-segoe text-white text-[20px] text-center cursor-not-allowed">
            <LockClosedIcon class="text-center size-4 align-middle me-1" style="display: inline;" />
            Perguntas para Ir Além
        </a>
        <router-link id="ciclos" to="/ciclos-educacionais"
            class=" w-[300px] p-[6px] bg-[#37A79F] rounded-[9px] font-bold-segoe text-white text-[20px] text-center">
            Ciclos Educacionais
        </router-link>
        <router-link id="sos" to="/sos"
            class=" w-[300px] p-[6px] bg-[#47C0B7] rounded-[9px] font-bold-segoe text-white text-[20px] text-center">
            s.o.s
        </router-link>
        <router-link id="silencio" to="/silencio"
            class=" w-[300px] p-[6px] bg-[#77BE4E] rounded-[9px] font-bold-segoe text-white text-[20px] text-center">
            Silêncio
        </router-link>
        <router-link id="progresso" to="/progresso"
            class=" w-[300px] p-[6px] bg-[#FD7062] rounded-[9px] font-bold-segoe text-white text-[20px] text-center">
            Progresso
        </router-link>
        <a id="sair" href="#"
            class=" w-[300px] p-[6px] bg-[#7a7a7a] rounded-[9px] font-bold-segoe text-white text-[20px] text-center"
            @click="logout">
            Sair
        </a>
    </div>
    <div class="bottom-0 w-full items-center justify-center text-center mt-2 mb-4">
        <img src="@/assets/logos/voceautentico-laranja.png" alt="" />
    </div>
</Slide>
</template>

<script setup>
import { ref, inject, onMounted, defineProps } from "vue";
import { Slide } from "vue3-burger-menu";
import startupTour from "@/utilities/custom-tour/startupTour/startup-tour";
import { useAuthStore } from "@/store";
import HelpButton from "@/components/Navbar/HelpButton.vue";
import { LockClosedIcon } from '@heroicons/vue/24/solid'
import { useRouter } from 'vue-router'

const router = useRouter();
const queryParams = ref(router.currentRoute.value.query);
const initialTour = inject("initialTour");
const props = defineProps(["bgColor"]);
const isOpen = ref(false);
const burgerIcon = ref(false);
const crossIcon = ref(true);
const menuWidthSize = ref(0);
const auth = useAuthStore();

const menuClicked = () => {
    isOpen.value = !isOpen.value;
};

const logout = () => {
    auth.setAuthentication(false);
    auth.includeRoles(null);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.href = "/";
};

const openSideMenuIfuserLogin = () => {
    if (queryParams.value.m === '1') {
        isOpen.value = true;
    }
};

onMounted(() => {
    openSideMenuIfuserLogin();
    menuWidthSize.value = window.innerWidth > 430 ? 430 : window.innerWidth;
    const startupTourArray = startupTour(initialTour, menuClicked);
    initialTour.addSteps(startupTourArray);
});
</script>

<style scoped>
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
    cursor: pointer;
}

.bm-burger-bars {
    background-color: #373a47;
}

.line-style {
    position: absolute;
    height: 20%;
    left: 0;
    right: 0;
}

.cross-style {
    position: absolute;
    top: 12px;
    right: 2px;
    cursor: pointer;
}

.bm-cross {
    background: #bdc3c7;
}

.bm-cross-button {
    height: 24px;
    width: 24px;
}

.bm-menu {
    height: 100%;
    /* 100% Full-height */
    width: 0;
    /* 0 width - change this with JavaScript */
    position: fixed;
    /* Stay in place */
    z-index: 1000;
    /* Stay on top */
    top: 0;
    left: 0;
    background-color: rgb(63, 63, 65);
    /* Black*/
    overflow-x: hidden;
    /* Disable horizontal scroll */
    padding-top: 60px;
    /* Place content 60px from the top */
    transition: 0.5s;
    /*0.5 second transition effect to slide in the sidenav*/
}

.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

.bm-item-list {
    color: #b8b7ad;
    margin-left: 10%;
    font-size: 20px;
}

.bm-item-list>* {
    display: flex;
    text-decoration: none;
    padding: 0.7em;
}

.bm-item-list>*>span {
    margin-left: 10px;
    font-weight: 700;
    color: white;
}
</style>
