import { defineStore } from 'pinia';

export const useStartupStore = defineStore("startup", {
    state: () => ({
        alertaPrivacidadeConfirmado: false,
        tourInicialRealizado: false,
        primeiroAcessoRealizado: false,
    }),
    actions: {
        confirmarAlertaPrivacidade(confirmado) {
            this.alertaPrivacidadeConfirmado = confirmado;
        },
        confirmarTourInicial(realizado) {
            this.tourInicialRealizado = realizado;
        },
        finalizarTour(action) {
            if (action) {
                switch (action) {
                    case '/acao':
                        this.tourInicialRealizado = true;
                        break;
                    default:
                        break;
                }
            }
        },
        iniciarTour(action) {
            if (action) {
                switch (action) {
                    case '/acao':
                        this.tourInicialRealizado = false;
                        break;
                    default:
                        break;
                }
            }
        },
        confirmarPrimeiroAcesso(primeiroAcessoRealizado) {
            this.primeiroAcessoRealizado = primeiroAcessoRealizado;
        },
        clear() {
            this.alertaPrivacidadeConfirmado = false;
            this.tourInicialRealizado = false;
            this.primeiroAcessoRealizado = false;
        },
    },
    getters: {
        alertaPrivacidade: (state) => state.alertaPrivacidadeConfirmado,
        tourInicial: (state) => state.tourInicialRealizado,
    },
    persist: {
        enabled: true,
        strategies: [
            {
                key: 'startupStore',
                storage: localStorage,
            },
        ],
    },
})
