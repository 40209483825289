<template>
  <div>
    <!-- Imagem -->
    <div class="logo-step flex items-center justify-center my-4">
      <img src="@/assets/icons/avatars/step-action-four.png" alt="">
      <img src="@/assets/icons/numbers/4.png" alt="">
    </div>
    <!-- Textos -->
    <h2 class="font-bold-segoe text-[#F37335] text-[23px] text-center uppercase">Passo 4</h2>
    <p class="text-center font-bold-segoe text-[#F37335] mb- mx-[58px]">
      Foque na REAÇÃO EXTERNA e responda:
    </p>
    <p class="font-italic-segoe text-[13px] text-[#727376] text-center mx-[58px]">
      Quais reações você expressou em relação ao fato ocorrido?
      Perceba que as reações internas são diferentes das externas, o
      corpo expressa impulsos automáticos.
    </p>
    <!-- Textarea -->
    <FormKit type="textarea" name="questao" placeholder="Observação: Nem sempre essas ações são visíveis aos outros."
      :classes="config_textarea" cols=50 v-model="response" :value="response" rows=5 />
    <!-- Botão -->
    <FormKit type="submit" :classes="config_submit" label="próximo" @click="submitForm" />
  </div>
</template>

<script setup>
import { useQuestionForm } from '@/modules/acao/composables/useQuestionForm';
import { config_textarea, config_submit } from '@/modules/acao/utilities/config_form';

const question = 'Foque na REAÇÃO EXTERNA e responda:';
const formId = 3;
const { store } = useQuestionForm();

const nextRoute = `/acao/${store.acao._id}/4`;

const { response, submitForm } = useQuestionForm(question, formId, nextRoute);
</script>

<style></style>
