<template>
<div>
    <div id="animacao" class="flex items-center justify-center my-5">
        <div id="circulo-branco"
            class="border h-[220px] w-[220px] rounded-full shadow-[-10px_-10px_30px_4px_rgba(55,167,159,0.5),_10px_10px_30px_4px_rgba(55,167,159,0.5)] flex items-center justify-center bg-white">
            <div class="h-[200px] w-[200px] rounded-full border-[#37A79F] border flex items-center justify-center">
                <div :class="{ 'breathing': inspirar, 'holding': segurar, 'exhaling': expirar, 'holdingFinal': segurarFim, 'ending': encerrar }"
                    class="h-[194px] w-[194px] rounded-full bg-[#7BCCC6] flex items-center justify-center">
                    <img src="@/assets/icons/avatars/respiracao.png" :class="{ 'hidden': ciclo }" alt="">
                </div>
                <p v-if="inspirar" class="fade-in font-bold-segoe text-[#727376] text-[2em] absolute">inspire...</p>
                <p v-if="segurar" class="fade-in font-bold-segoe text-[#727376] text-[2em] absolute">segure...</p>
                <p v-if="expirar" class="fade-in font-bold-segoe text-[#727376] text-[2em] absolute">expire...</p>
            </div>
        </div>
    </div>
    <div class="flex flex-col items-center justify-center text-center mx-[60px]">
        <h1 v-if="!ciclo" class="font-bold-segoe text-[#47C0B7] text-[1.5rem] leading-6 mb-3">
            Respire <br />
            para Acalmar-se
        </h1>
        <h1 v-if="ciclo" class="font-bold-segoe text-[#47C0B7] text-[1.5rem] leading-6 mb-3">
            Respirar é voltar <br />
            ao momento <br />
            presente.
        </h1>
        <p v-if="!ciclo" class="text-[#47C0b7] text-[15px] font-bold-segoe leading-5 mb-2">
            Reconheça o oxigênio entrando e saindo
            do seu corpo sem julgamento.
        </p>
        <p v-if="!ciclo" class="text-[13px] text-[#727376] font-italic-segoe">
            Repita os ciclos quantas vezes achar necessário.<br />
            Escolha quantas vezes deseja fazer o ciclo de respiração.
        </p>
    </div>
    <div v-if="!ciclo" class="mt-5 flex gap-10 justify-center items-center">
        <button
            class="border border-[#47C0B7] rounded-[10px] h-[40px] w-[40px] text-[#47C0B7] text-[20px] flex items-center justify-center font-bold-segoe"
            @click="less">-</button>

        <div class="flex items-center justify-center">
            <FormKit type="number" number="integer" step="1" name="repeticao" min=1 :value="qtde"
                :classes="config_select" :disabled="true" v-model="qtde" />
            <span class="p-5 text-center text-[#47C0B7] font-bold-segoe">
                {{ qtdeComTexto }}
            </span>
        </div>

        <button
            class="border border-[#47C0B7] rounded-[10px] h-[40px] w-[40px] text-[#47C0B7] text-[20px] flex items-center justify-center font-bold-segoe"
            @click.prevent="more">+</button>
    </div>
    <div class="flex items-center justify-center">
        <button v-if="!ciclo"
            class="font-bold-segoe bg-[#47C0B7] outline-none border-none rounded-[15px] text-[#FFF] text-[14px] p-[15px] my-8 mx-[27px] w-[80%]"
            @click="inicio">
            iniciar
        </button>
        <button v-if="ciclo"
            class="font-bold-segoe bg-[#47C0B7] outline-none border-none rounded-[15px] text-[#FFF] text-[14px] p-[15px] my-8 mx-[27px] w-[80%]"
            @click="parar">
            parar
        </button>
    </div>
</div>
</template>

<script setup>
import { useRespirar } from '@/modules/respiracao/composables/useRespirar';

const { qtde, ciclo, inspirar, segurar, segurarFim, expirar, encerrar, config_select, qtdeComTexto, less, more, inicio, parar } = useRespirar();
</script>

<style scoped>
.breathing {
    animation: pulse 6s infinite alternate;
}

@keyframes pulse {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1.00);
    }
}

.holdingFinal {
    animation: hold 6s forwards;
}

.exhaling {
    animation: exhale 6s ease-in-out forwards;
}

@keyframes exhale {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

.fade-in {
    opacity: 0;
    animation: fadeInAnimation 0.1s ease forwards;
}

@keyframes hold {
    0% {
        transform: scale(0);
        /* Mantém o tamanho atual */
    }

    100% {
        transform: scale(0);
        /* Mantém o tamanho até o final da animação */
    }
}

@keyframes fadeInAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.ending {
    background-color: transparent;
    animation: fadeInAnimation 0.1s ease forwards;
}
</style>
