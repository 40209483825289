<template>
<div class="flex items-start justify-center mt-9">
    <a href="#" class="text-center text-white cursor-help" @click="showModal">Esqueci minha senha</a>
</div>
<VueFinalModal class="flex justify-center items-center bg-[rgba(0,0,0,0.5)]"
    content-class="flex flex-col items-center justify-center max-w-xl mx-4 p-2 border rounded-lg space-y-2 bg-[#FEEEE2] border"
    :class="borderColor" v-model:model-value="show">
    <h1 class="font-bold-segoe text-sm mt-3 text-center" :class="textColor">
        Informe seu e-mail do login, para cadastrar uma nova senha
    </h1>
    <input v-model="email" type="email" placeholder="Digite seu email" @input="email = toLowerCase(email)"
        class="border border-[#F37334] p-2 rounded-lg w-[90%] mx-[10%] my-2" required />
    <div class="flex gap-6">
        <button class="font-bold-segoe mt-1 m-auto p-2 px-4 border text-[#FFF] text-sm rounded-lg w-[200px]"
            :class="bgColor" @click.prevent="requestPasswordReset">
            Recuperar senha
        </button>
    </div>
</VueFinalModal>
<modal-alert v-model="showAlert" title="Atenção" @confirm="confirmAlert">
    <div v-html="contentAlert"></div>
</modal-alert>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { VueFinalModal } from 'vue-final-modal';
import ModalAlert from '@/components/Modals/ModalAlert.vue';
import axios from 'axios';

const show = ref(false);
const showAlert = ref(false);
const bgColor = ref('#F37334');
const textColor = ref('#F37334');
const borderColor = ref('#F37334');
const email = ref('');
const contentAlert = ref('');

const showModal = () => {
    show.value = true;
};

const confirmAlert = () => {
    showAlert.value = false;
};

const showModalAlert = (message) => {
    if (!message) {
        message = '<p>E-mail de recuperação enviado.</p><p>Verifique o seu e-mail e proceda com as instruções. Certifique-se que o e-mail para recuperação de senha, não esteja na caixa de spam.</p>';
    }

    contentAlert.value = message;
    show.value = false;
    showAlert.value = true;
};

const requestPasswordReset = async () => {
    if (!email.value) {
        showModalAlert('<p>Informe o e-mail para recuperação de senha.</p>');
        return;
    }

    try {
        const url = `${process.env.VUE_APP_API_URL}/recover/request-password-reset`;
        const params = { email: email.value };
        const response = await axios.post(url, params);

        if (response.status < 300) {
            email.value = '';
            showModalAlert();
        }
    }
    catch (error) {
        if (error.status === 404) {
            showModalAlert('<p>E-mail não encontrado.</p><p>Verifique o e-mail informado e tente novamente.</p>');
            return;
        }

        showModalAlert('<p>Erro ao enviar e-mail de recuperação.</p>');
    }
}

const toLowerCase = (text) => {
    return text.toLowerCase();
}

onMounted(() => {
    bgColor.value = 'bg-[#F37334]';
    textColor.value = 'text-[#F37334]';
    borderColor.value = 'border-[#F37334]';
});

</script>
