import { ref, computed } from "vue";
import NoSleep from "nosleep.js";

export const useRespirar = () => {
    const noSleep = new NoSleep();

    // Controladores da respiração
    const qtde = ref(1);
    const ciclo = ref(false);
    const inspirar = ref(false);
    const segurar = ref(false);
    const segurarFim = ref(false);
    const expirar = ref(false);
    const encerrar = ref(false);

    let inspirarTimeout;
    let segurarTimeout;
    let expirarTimeout;
    let encerrarTimeout;

    const config_select = {
        wrapper: "flex justify-center items-center hidden ",
    };

    const qtdeComTexto = computed(() => {
        return `${qtde.value} ${qtde.value === 1 ? "repetição" : "repetições"}`;
    });

    // Função para diminuir e aumentar repetições:
    const less = () => {
        if (qtde.value > 1) qtde.value -= 1;
        // console.log("LESS: ", qtde.value);
    };

    const more = () => {
        qtde.value += 1;
        // console.log("MORE: ", qtde.value);
    };

    // Função INICIAL RESPIRAÇÃO
    const inicio = () => {
        ciclo.value = true;
        noSleep.enable();
        iniciarCiclo();
    };

    // Função para PARAR
    const parar = () => {
        clearTimeout(inspirarTimeout);
        clearTimeout(segurarTimeout);
        clearTimeout(expirarTimeout);
        clearTimeout(encerrarTimeout);
        noSleep.disable();
        ciclo.value = false;
        inspirar.value = false;
        segurar.value = false;
        expirar.value = false;
        encerrar.value = false;
    };

    // Função para INICIAR o ciclo
    const iniciarCiclo = () => {
        let repeticoes = qtde.value;
        realizarCiclo(repeticoes);
    };

    const realizarCiclo = (repeticoes) => {
        if (repeticoes < 1) {
            parar();
            return;
        }

        inspirar.value = true;
        inspirarTimeout = setTimeout(() => {
            inspirar.value = false;
            segurar.value = true;
            segurarTimeout = setTimeout(() => {
                segurar.value = false;
                expirar.value = true;
                expirarTimeout = setTimeout(() => {
                    expirar.value = false;
                    segurar.value = true;
                    segurarFim.value = true;
                    segurarTimeout = setTimeout(() => {
                        segurar.value = false;
                        segurarFim.value = false;
                        encerrar.value = true;
                        encerrarTimeout = setTimeout(() => {
                            encerrar.value = false;
                            repeticoes--;
                            realizarCiclo(repeticoes);
                        }, 10); // Tempo de encerramento do ciclo
                    }, 3000); // Tempo de segurar a respiração
                }, 6000); // Tempo de expiração
            }, 3000); // Tempo de segurar a respiração
        }, 6000); // Tempo de inspiração
    };

    return {
        qtde,
        ciclo,
        inspirar,
        segurar,
        segurarFim,
        expirar,
        encerrar,
        config_select,
        qtdeComTexto,
        less,
        more,
        inicio,
        parar,
    };
};
