<template>
  <div>
    <!-- Imagem -->
    <div class="logo-step flex items-center justify-center my-2">
      <img src="@/assets/icons/avatars/step-action-two.png" alt="">
      <img src="@/assets/icons/numbers/2.png" alt="">
    </div>
    <!-- Textos -->
    <h2 class="font-bold-segoe text-[#f34b21] text-[23px] text-center uppercase">Passo 2</h2>
    <p class="text-center font-bold-segoe text-[#f34b21] mb-5 mx-[58px]">
      Foque no seu PENSAMENTO e responda:
    </p>
    <p class="font-italic-segoe text-[13px] text-[#727376] text-center mx-[58px]">
      Quais pensamentos foram saltando automaticamente em sua mente enquanto você vivenciava o fato ocorrido?
    </p>
    <!-- Textarea -->
    <FormKit type="textarea" name="questao" placeholder="Sugestão: Faça em tópicos." :classes="config_textarea"
      v-model="response" :value="response" cols=50 rows=5 />
    <!-- Botão -->
    <FormKit type="submit" :classes="config_submit" label="próximo" @click="submitForm" />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useQuestionForm } from '@/modules/acao/composables/useQuestionForm';
import { config_textarea, config_submit } from '@/modules/acao/utilities/config_form';
const { store } = useQuestionForm();


const question = 'Foque no seu PENSAMENTO e responda:';
const formId = 1;
const nextRoute = `/acao/${store.acao._id}/2`;

const { response, submitForm } = useQuestionForm(question, formId, nextRoute);
</script>

<style></style>
