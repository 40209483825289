import { ref } from "vue";
import axios from '@/utilities/axios'

export const acoesCarregadas = ref(false);
export const acoesList = ref([]);
const loading = ref(false);
const actionBaseUrl = `${process.env.VUE_APP_API_URL}/action`;

export const carregarAcoes = async () => {
  loading.value = true; // Define o estado de carregamento como true

  try {
    const { data } = await axios.get(actionBaseUrl);
    acoesList.value = [
      ...data.acoesNaoRespondidasFaceis.map((acao) => ({
        ...acao,
        difficulty: "beginner",
      })),
      ...data.acoesNaoRespondidasIntermediarias.map((acao) => ({
        ...acao,
        difficulty: "intermediary",
      })),
      ...data.acoesNaoRespondidasAvancadas.map((acao) => ({
        ...acao,
        difficulty: "advanced",
      })),
    ];
    acoesCarregadas.value = true;
  } catch (error) {
    console.error("ERROR: ", error);
  } finally {
    loading.value = false; // Define o estado de carregamento como false
  }
};
