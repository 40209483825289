<template>
<VueFinalModal class="flex justify-center items-center"
    content-class="vfm flex flex-col max-w-xl mx-4 p-4 rounded-lg space-y-2 bg-[#FEEEE2] w-[90%]"
    @update:model-value="val => emit('update:modelValue', val)" @click="handleModalClick($event)">
    <h1 class="text-xl font-bold text-[#F37335] text-center">
        {{ title }}
    </h1>
    <div class="text-[#727376] italic text-center py-4 font-size" >
        <slot />
    </div>
    <button class="font-bold-segoe mt-1 m-auto p-2 px-4 border bg-[#F37334] text-[#FFF] rounded-lg"
        @click="emit('confirm')">
        OK
    </button>
    <button v-if="showCancel" class="font-bold-segoe mt-1 m-auto p-2 px-4 border bg-[#F37334] text-[#FFF] rounded-lg"
        @click="emit('cancel')">
        Cancelar
    </button>
</VueFinalModal>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import { VueFinalModal } from 'vue-final-modal';

// Define as propriedades do componente
const props = defineProps({
    title: String,
    showCancel: {
        type: Boolean,
        default: false,
    },
});

// Define os eventos emitidos pelo componente
const emit = defineEmits(['update:modelValue', 'confirm', 'click-outside', 'cancel']);

// Função para lidar com o clique dentro do modal
const handleModalClick = (event) => {
    // Verifica se o clique ocorreu fora do conteúdo do modal
    if (!event.target.closest('vfm')) {
        // Emite o evento click-outside
        emit('click-outside');
    }
};
</script>
<style scoped>
.font-size {
    font-size: 15px;
}
</style>
