<template>
<VueFinalModal class="flex justify-center items-center bg-[rgba(0,0,0,0.5)]"
    content-class="flex flex-col items-center justify-center max-w-xl mx-4 p-2 bg-white border rounded-lg space-y-2 bg-[#FEEEE1] border"
    :class="borderColor" @update:model-value="val => emit('update:modelValue', val)">
    <h1 class="font-bold-segoe text-sm mt-3" :class="textColor">
        {{ title }}
    </h1>
    <slot />
    <div class="flex gap-6">
        <button class="font-bold-segoe mt-1 m-auto p-2 px-4 border text-[#FFF] text-sm rounded-lg w-[140px]"
            :class="bgColor" @click="emit('back')">
            voltar
        </button>
        <button class="font-bold-segoe mt-1 m-auto p-2 px-4 border text-[#FFF] text-sm rounded-lg w-[140px]"
            :class="bgColor" @click="emit('create')">
            próximo
        </button>
    </div>
</VueFinalModal>
</template>

<script setup>
import { defineProps, defineEmits, onMounted, ref } from 'vue';
import { VueFinalModal } from 'vue-final-modal';

const bgColor = ref('#F37334');
const textColor = ref('#F37334');
const borderColor = ref('#F37334');

// Define as propriedades do componente
const props = defineProps({
    title: String,
    color: String,
});

// Define os eventos emitidos pelo componente
const emit = defineEmits([('update:modelValue', 'create'), ('back')]);

onMounted(() => {
    if (props.color !== undefined) {
        bgColor.value = `bg-[${props.color}]`;
        textColor.value = `text-[${props.color}]`;
        borderColor.value = `border-[${props.color}]`;
    }
    else {
        bgColor.value = 'bg-[#F37334]';
        textColor.value = 'text-[#F37334]';
        borderColor.value = 'border-[#F37334]';
    }
});

</script>
