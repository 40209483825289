<template>
<div class="content mt-[29px]" v-if="loading">
    <div id="header-perfil">
        <h1 class="font-bold-segoe text-center text-2xl text-[#F37335] leading-none mx-[58px] mb-3">
            Olá {{ userName }} 🤩
        </h1>
        <p class="font-italic-segoe text-[#727376] mb-5 mx-[58px]">
            Abaixo a lista dos novos usuários:
        </p>
    </div>
    <div class="mx-[28px]">
        <DataView :value="allUsers" paginator :rows="5">
            <template #list="slotProps">
                <div class="flex flex-wrap">
                    <div v-for="(item, index) in slotProps.items" :key="index" class="w-full p-3">
                        <div class="flex flex-col xl:flex-row xl:items-start p-4 gap-4"
                            :class="{ 'border-t border-surface-200 dark:border-surface-700': index !== 0 }">
                            <div
                                class="flex flex-col sm:flex-row justify-between items-center xl:items-start flex-1 gap-4">
                                <div class="flex flex-col items-center sm:items-start gap-3">
                                    <div class="text-md text-surface-900">{{ item.name }}</div>
                                    <div class="flex items-center gap-3">
                                        <span class="flex items-center gap-2">
                                            <span class="">{{ item.email }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="flex sm:flex-col items-center sm:items-end gap-3 sm:gap-2">
                                    <span class="">{{ item.cel }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </DataView>

    </div>



    <!-- Formulário -->
    <!--
      <FormKit :disabled="!editar" type="text" name="name" id="name" label="Nome Completo" validation-visibility="live"
      validation-label="Nome Completo" :validation-messages="{ required: 'Nome completo é obrigatório' }"
        :classes="style_config" v-model="user.name" />

      <FormKit :disabled="!editar" type="date" name="birthday" id="birthday" label="Data de Nascimento"
        :validation="[['date_between', antes, hoje]]" :min="antes" :max="hoje" validation-visibility="live"
        validation-label="Data de Nascimento"
        :validation-messages="{ required: 'Data é obrigatório', date_between: `A data deve ser entre os anos ${antes.getUTCFullYear()} e ${hoje.getUTCFullYear()}.` }"
        :classes="style_config" v-model="nascimento" />

      <FormKit :disabled="!editar" type="select" name="gender" id="gender" label="Gênero" :classes="style_config"
        :options="[
          { label: 'Selecione um gênero', attrs: { disabled: true } },
          { label: 'Masculino', value: 'male' },
          { label: 'Feminino', value: 'female' },
          { label: 'Prefiro não informar', value: 'other' }
        ]" v-model="gender" />

      <FormKit :disabled="!editar" type="tel" name="whatsapp" id="whatsapp" label="Celular | Whatsapp"
        :classes="style_config" v-maska:[option]="maskedValue" placeholder="(DD) 99999-9999" v-model="whatsapp" />


      <FormKit v-if="!editar" type="button" label="Editar" name="editar" id="editar" :classes="{
        wrapper: 'flex flex-col',
        input: 'font-bold-segoe  bg-[#F37335] outline-none border-none rounded-md text-[#FFF] text-[14px] p-[15px] my-8'
      }" @click="() => edit()" />
      <FormKit v-if="editar" type="submit" label="Salvar" name="submit" id="submit" :classes="{
        wrapper: 'flex flex-col',
        input: 'font-bold-segoe  bg-[#68A74E] outline-none border-none rounded-md text-[#FFF] text-[14px] p-[15px] my-8',
      }" @click="salvar" />
-->
</div>
</template>

<script setup>
import { ref, inject } from 'vue';
import DataView from 'primevue/dataview';


const axios = inject('axios');
const loading = ref(false);


const allUsers = ref([])
const reqUsers = async () => {
    const adminBaseUrl = `${process.env.VUE_APP_API_URL}/admin`;
    await axios.get(`${adminBaseUrl}/admin`)
        .then(({ data }) => {
            if (data.success) {
                console.log('allUsers: ', data);
                allUsers.value = data.users;
                loading.value = true;
            }
        })
}






try {
    reqUsers();

} catch (error) {
    console.log('CATCH ERROR: ', error)
}


</script>



<style scoped></style>
