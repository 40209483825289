<script setup>

</script>
<template>
  <div class="mx-7 flex flex-col items-center justify-center">
    <div class="flex flex-col items-center justify-center">
      <h1 class="text-[#2F8E87] text-[26px] text-center font-bold-segoe leading-6 my-5">
        Resiliência
      </h1>
    </div>
    <div class="w-full">
      <div class="border-t-2 w-full flex gap-4 py-1 my-1">
        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29">
          <circle id="Elipse_18" data-name="Elipse 18" cx="14.5" cy="14.5" r="14.5" fill="#37a79f" />
          <path id="Polígono_1" data-name="Polígono 1"
            d="M6.336,2.5a2,2,0,0,1,3.328,0l4.263,6.394A2,2,0,0,1,12.263,12H3.737A2,2,0,0,1,2.073,8.891Z"
            transform="translate(22 7) rotate(90)" fill="#fff" />
        </svg>
        <span class="font-bold-segoe text-[#37A79F]"> Introdução</span>
      </div>
      <div class="border-t-2 w-full flex gap-4 py-1 my-1">
        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29">
          <circle id="Elipse_18" data-name="Elipse 18" cx="14.5" cy="14.5" r="14.5" fill="#37a79f" />
          <path id="Polígono_1" data-name="Polígono 1"
            d="M6.336,2.5a2,2,0,0,1,3.328,0l4.263,6.394A2,2,0,0,1,12.263,12H3.737A2,2,0,0,1,2.073,8.891Z"
            transform="translate(22 7) rotate(90)" fill="#fff" />
        </svg>
        <span class="font-bold-segoe text-[#37A79F]">Nome do áudio 01</span>
      </div>
      <div class="border-t-2 w-full flex gap-4 py-1 my-1">
        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29">
          <circle id="Elipse_18" data-name="Elipse 18" cx="14.5" cy="14.5" r="14.5" fill="#37a79f" />
          <path id="Polígono_1" data-name="Polígono 1"
            d="M6.336,2.5a2,2,0,0,1,3.328,0l4.263,6.394A2,2,0,0,1,12.263,12H3.737A2,2,0,0,1,2.073,8.891Z"
            transform="translate(22 7) rotate(90)" fill="#fff" />
        </svg>
        <span class="font-bold-segoe text-[#37A79F]">Nome do áudio 02</span>
      </div>
      <div class="border-t-2 w-full flex gap-4 py-1 my-1">
        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29">
          <circle id="Elipse_18" data-name="Elipse 18" cx="14.5" cy="14.5" r="14.5" fill="#37a79f" />
          <path id="Polígono_1" data-name="Polígono 1"
            d="M6.336,2.5a2,2,0,0,1,3.328,0l4.263,6.394A2,2,0,0,1,12.263,12H3.737A2,2,0,0,1,2.073,8.891Z"
            transform="translate(22 7) rotate(90)" fill="#fff" />
        </svg>
        <span class="font-bold-segoe text-[#37A79F]">Nome do áudio 03</span>
      </div>
      <div class="border-t-2 w-full flex gap-4 py-1 my-1">
        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29">
          <circle id="Elipse_18" data-name="Elipse 18" cx="14.5" cy="14.5" r="14.5" fill="#37a79f" />
          <path id="Polígono_1" data-name="Polígono 1"
            d="M6.336,2.5a2,2,0,0,1,3.328,0l4.263,6.394A2,2,0,0,1,12.263,12H3.737A2,2,0,0,1,2.073,8.891Z"
            transform="translate(22 7) rotate(90)" fill="#fff" />
        </svg>
        <span class="font-bold-segoe text-[#37A79F]">Nome do áudio 04</span>
      </div>
      <div class="border-t-2 w-full flex gap-4 py-1 my-1">
        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29">
          <circle id="Elipse_18" data-name="Elipse 18" cx="14.5" cy="14.5" r="14.5" fill="#37a79f" />
          <path id="Polígono_1" data-name="Polígono 1"
            d="M6.336,2.5a2,2,0,0,1,3.328,0l4.263,6.394A2,2,0,0,1,12.263,12H3.737A2,2,0,0,1,2.073,8.891Z"
            transform="translate(22 7) rotate(90)" fill="#fff" />
        </svg>
        <span class="font-bold-segoe text-[#37A79F]">Nome do áudio 05</span>
      </div>
      <div class="border-t-2 w-full flex gap-4 py-1 my-1">
        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29">
          <circle id="Elipse_18" data-name="Elipse 18" cx="14.5" cy="14.5" r="14.5" fill="#37a79f" />
          <path id="Polígono_1" data-name="Polígono 1"
            d="M6.336,2.5a2,2,0,0,1,3.328,0l4.263,6.394A2,2,0,0,1,12.263,12H3.737A2,2,0,0,1,2.073,8.891Z"
            transform="translate(22 7) rotate(90)" fill="#fff" />
        </svg>
        <span class="font-bold-segoe text-[#37A79F]">Nome do áudio 06</span>
      </div>
      <div class="border-t-2 w-full flex gap-4 py-1 my-1">
        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29">
          <circle id="Elipse_18" data-name="Elipse 18" cx="14.5" cy="14.5" r="14.5" fill="#37a79f" />
          <path id="Polígono_1" data-name="Polígono 1"
            d="M6.336,2.5a2,2,0,0,1,3.328,0l4.263,6.394A2,2,0,0,1,12.263,12H3.737A2,2,0,0,1,2.073,8.891Z"
            transform="translate(22 7) rotate(90)" fill="#fff" />
        </svg>
        <span class="font-bold-segoe text-[#37A79F]">Nome do áudio 07</span>
      </div>



    </div>




  </div>
</template>