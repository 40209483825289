import { defineStore } from "pinia";
import axios from "@/utilities/axios.js";
const actionBaseUrl = `${process.env.VUE_APP_API_URL}/action`;

export const useQuestionResponse = defineStore("question", {
    state: () => ({
        responseQuestion: [],
        acao: {},
    }),
    actions: {
        addQuestion(response) {
            console.log("Acao: ", 'addQuestion');

            const existingResponse = this.responseQuestion.find(
                (item) => item.id === response.id
            );

            if (existingResponse) {
                existingResponse.response = response.response; // Atualiza a resposta existente
            }
            else {
                this.responseQuestion.push(response); // Adiciona a nova resposta se não existir
            }
        },
        async requestAcao(id) {
            console.log("Acao: ", 'requestAcao');

            try {
                const data = await axios.get(`${actionBaseUrl}/${id}`);

                if (data.data.success === true) {
                    this.acao = data.data.action;
                }
            } catch (error) {
                alert(error);
            }
        },
        async requestResponse() {
            console.log("Acao: ", 'requestResponse');

            try {
                await axios.post(
                    `${actionBaseUrl}/${this.acao._id}`,
                    this.responseQuestion
                );
            } catch (error) {
                alert(error);
                console.log("ERRO store.js: ", error);
            }
        },
        async requestFato(textContent) {
            console.log("Acao: ", 'requestFato');

            const textoFato = {
                textContent
            }
            try {
                console.log("TextoFato: ", textoFato);
                console.log("actionBaseUrl: ", actionBaseUrl);

                const fato = await axios.post(`${actionBaseUrl}/save2`, textoFato);

                console.log("Fato POST: ", fato);

                if (fato.data.success === true) {
                    this.acao = fato.data.acao
                }
            } catch (error) {
                alert(error);
                console.log("ERRO store.js: ", error);
            }
        },
    }
});

export const useAuthStore = defineStore("auth", {
    state: () => ({
        isAuthenticated: false,
        roles: [],
        acceptedPolicy: false,
        lastLogin: 0,
        userId: '',
    }),
    actions: {
        setAuthentication(isAuthenticated) {
            this.isAuthenticated = isAuthenticated;
        },
        includeRoles(roles) {
            this.roles = roles;
        },
        setAcceptedPolicy(acceptedPolicy) {
            this.acceptedPolicy = acceptedPolicy;
        },
        setLastLogin() {
            if (this.lastLogin === 0) {
                this.lastLogin = new Date().getTime();
                this.acceptedPolicy = false;
                return;
            }

            const now = new Date().getTime();
            const expiry = this.lastLogin + 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds

            if (now > expiry) {
                this.lastLogin = now;
                this.acceptedPolicy = false;
                return;
            }

            this.lastLogin = now;
        },
        setUserId(userId) {
            this.userId = userId;
        },
        clear() {
            this.isAuthenticated = false;
            this.roles = [];
            this.acceptedPolicy = false;
            this.lastLogin = 0;
            this.userId = '';
            localStorage.removeItem("token");
        }
    },
    persist: true,
})
