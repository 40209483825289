<template>
  <div class="mx-[28px]" v-if="loading">
    <div class="text-center my-4">
      <h1 class="font-bold-segoe text-[#FD7862] text-[26px] mb-3 leading-7">Diário de <br />ações de bem-estar</h1>
      <p class="font-italic-segoe text-[13px] text-[#727376] text-center mx-[15px]">Este diário te leva a
        reconhecer que
        simples ações
        podem
        ter um impacto positivo no seu bem-estar. Aqui, ao longo
        do tempo, você pode conferir a expansão do seu repertório
        cognitivo-emocional. </p>
    </div>
    <div class=" flex flex-col items-center justify-center">
      <div v-for="questao in user.questions" :key="questao.idAction" class="mb-2">
        <router-link v-if="questao.difficulty !== 'custom'" :to="'/historico/' + questao.idAction">
          <div class="w-80 bg-[#FED2CD] border-[#FD7062] border rounded-[10px] p-2 text-center">
            <p class="text-[#FD5445] text-sm font-bold-segoe">{{ questao.textAction }}</p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { usePerfil } from '@/modules/progresso/composables/usePerfil';

const { user, loading, fetchPerfil } = usePerfil();

onMounted(() => {
  fetchPerfil();
});
</script>

<style scoped></style>
