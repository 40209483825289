<template>
  <VueFinalModal :class="modalClass" :content-class="contentClass"
    @update:model-value="val => emit('update:modelValue', val)" @click="handleModalClick($event)">
    <h1 v-if="title" :class="titleClass">
      {{ title }}
    </h1>
    <slot />
    <button :class="buttonClass" @click="emit('confirm')">
      OK
    </button>
  </VueFinalModal>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import { VueFinalModal } from 'vue-final-modal';

// Define as propriedades do componente
const props = defineProps({
  title: String,
  modalClass: {
    type: String,
    default: 'flex justify-center items-center bg-[rgba(0,0,0,0.5)]'
  },
  contentClass: {
    type: String,
    default: 'vfm flex flex-col max-w-xl mx-4 p-4 bg-white border rounded-lg space-y-2 bg-[#FEEEE2] border-[#F37334]'
  },
  titleClass: {
    type: String,
    default: 'text-xl'
  },
  buttonClass: {
    type: String,
    default: 'font-bold-segoe mt-1 m-auto p-2 px-4 border bg-[#F37334] text-[#FFF] rounded-lg'
  },
});

// Define os eventos emitidos pelo componente
const emit = defineEmits(['update:modelValue', 'confirm', 'click-outside']);

// Função para lidar com o clique dentro do modal
const handleModalClick = (event) => {
  // Verifica se o clique ocorreu fora do conteúdo do modal
  if (!event.target.closest('vfm')) {
    // Emite o evento click-outside
    emit('click-outside');
  }
};
</script>
