<template>
  <div>
    <!-- Imagem -->
    <div class="logo-step flex items-center justify-center my-4">
      <img src="@/assets/icons/avatars/step-action-three.png" alt="">
      <img src="@/assets/icons/numbers/3.png" alt="">
    </div>
    <!-- Textos -->
    <h2 class="font-bold-segoe text-[#F37335] text-[23px] text-center uppercase">Passo 3</h2>
    <p class="text-center font-bold-segoe text-[#F37335] mb- mx-[58px]">
      Foque no seu SENSAÇÃO e responda:
    </p>
    <p class="font-italic-segoe text-[13px] text-[#727376] text-center mx-[58px]">
      Quais sensações você sentiu no seu corpo durante o fato?
      Perceba como está a sua cabeça, garganta, coração,
      estômago, braços e pernas; como se você pudesse escanear o
      corpo por dentro...
    </p>
    <!-- Textarea -->
    <FormKit type="textarea" name="questao" placeholder="Observação: Nem sempre essas ações são visíveis aos outros."
      :classes="config_textarea" cols=50 v-model="response" :value="response" rows=5 />
    <!-- Botão -->
    <FormKit type="submit" :classes="config_submit" label="próximo" @click="submitForm" />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useQuestionForm } from '@/modules/acao/composables/useQuestionForm';
import { config_textarea, config_submit } from '@/modules/acao/utilities/config_form';
const { store } = useQuestionForm();


const question = 'Foque no seu SENSAÇÃO e responda:';
const formId = 2;
const nextRoute = `/acao/${store.acao._id}/3`;

const { response, submitForm } = useQuestionForm(question, formId, nextRoute);
</script>

<style></style>
