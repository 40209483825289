import { ref, computed } from "vue";
import moment from "moment";
import NoSleep from "nosleep.js";

export const useCronometro = () => {
  const qtde = ref(1);
  const ativado = ref(false);
  let countdownTimer = null;
  const progressPercentage = ref(0);
  const countdown = ref("");
  const horaInicio = ref("");
  const horaTermino = ref("");
  const noSleep = new NoSleep();

  const qtdeComTexto = computed(() => {
    return `${qtde.value} ${qtde.value === 1 ? "minuto" : "minutos"}`;
  });

  const config_select = {
    wrapper: "flex justify-center items-center hidden ",
  };

  const less = () => {
    if (qtde.value > 1) qtde.value -= 1;
  };

  const more = () => {
    qtde.value += 1;
  };

  const calcularHoraTermino = () => {
    if (horaInicio.value && qtde.value) {
      const inicio = moment(horaInicio.value, "HH:mm"); // Converte para objeto Moment
      const termino = inicio.add(qtde.value, "minutes"); // Adiciona os minutos ao horário de início
      horaTermino.value = termino.format("HH:mm"); // Formata o horário de término como 'HH:mm'
    }
  };

  const iniciarSilencio = () => {
    ativado.value = true;
    noSleep.enable();
    startCountdown();
    const now = moment().format("HH:mm");
    horaInicio.value = now;
    calcularHoraTermino();
  };

  const pararSilencio = () => {
    countdown.value = "";
    ativado.value = false;
    noSleep.disable();
    clearInterval(countdownTimer);
  };

  const startCountdown = () => {
    let seconds = qtde.value * 60; // transforma em segundos
    countdownTimer = setInterval(() => {
      seconds--;
      if (seconds <= 0) {
        clearInterval(countdownTimer);
        noSleep.disable();
        ativado.value = false;
        return;
      }
      updateProgress(seconds);
    }, 1000); // atualiza a cada segundo
  };

  const updateProgress = (seconds) => {
    progressPercentage.value = (seconds / (qtde.value * 60)) * 100;

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedTime = `${minutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
    countdown.value = formattedTime;
  };

  return {
    qtde,
    ativado,
    progressPercentage,
    countdown,
    horaInicio,
    horaTermino,
    qtdeComTexto,
    config_select,
    less,
    more,
    iniciarSilencio,
    pararSilencio,
  };
};
