  <script setup>
  import { defineProps, computed } from 'vue';
  import logoWhiteBgBlue from '@/assets/logos/logo-white-bg-blue.svg';
  import logoWhiteBgDarkBlue from '@/assets/logos/logo-white-bg-darkblue.svg';
  import logoWhiteBgDarkGreen from '@/assets/logos/logo-white-bg-darkgreen.svg';
  import logoWhiteBgGreen from '@/assets/logos/logo-white-bg-green.svg';
  import logoWhiteBgOrange from '@/assets/logos/logo-white-bg-orange.svg';
  import logoWhiteBgRed from '@/assets/logos/logo-white-bg-red.svg';
  import logoWhite from '@/assets/logos/logo-white.svg';
  import logoGreen from '@/assets/logos/logo-green.svg';
  import logoOrange from '@/assets/logos/logo-orange.svg';
  import logoWhiteBgDDBlue from '@/assets/logos/logo-white-bg-ddblue.svg';

  import borderHeaderBlue from '@/assets/borders/border-header-blue.svg';
  import borderHeaderDarkBlue from '@/assets/borders/border-header-darkblue.svg';
  import borderHeaderDarkGreen from '@/assets/borders/border-header-darkgreen.svg';
  import borderHeaderGreen from '@/assets/borders/border-header-green.svg';
  import borderHeaderGreenAG from '@/assets/borders/border-header-green-ag.svg';
  import borderHeaderOrange from '@/assets/borders/border-header-orange.svg';
  import borderHeaderRed from '@/assets/borders/border-header-red.svg';
  import borderHeaderDDBlue from '@/assets/borders/border-header-ddblue.svg';

  const props = defineProps(['logo', 'border']);

  const logo = computed(() => {
    switch (props.logo) {
      case 'orange':
        return logoWhiteBgOrange;
      case 'blue':
        return logoWhiteBgBlue;
      case 'darkblue':
        return logoWhiteBgDarkBlue;
      case 'ddblue':
        return logoWhiteBgDDBlue;
      case 'darkgreen':
        return logoWhiteBgDarkGreen;
      case 'green':
        return logoWhiteBgGreen;
      case 'red':
        return logoWhiteBgRed;
      case 'only-white':
        return logoWhite;
      case 'only-green':
        return logoGreen;
      case 'only-orange':
        return logoOrange;
      default:
        return '';
    }
  });

  const border = computed(() => {
    switch (props.border) {
      case 'orange':
        return borderHeaderOrange;
      case 'blue':
        return borderHeaderBlue;
      case 'darkblue':
        return borderHeaderDarkBlue;
      case 'ddblue':
        return borderHeaderDDBlue;
      case 'darkgreen':
        return borderHeaderDarkGreen;
      case 'green':
        return borderHeaderGreen;
      case 'green-ag':
        return borderHeaderGreenAG;
      case 'red':
        return borderHeaderRed;
      default:
        return '';
    }
  });


</script>


<template>
  <div class="flex flex-col justify-center items-center mt-5 w-[100%] mb-[-48px]">
    <img :src="logo" alt="logo Hooy" id="logo" class="w-[190.79px]">
    <!-- <img :src="border" alt="slogan Você Presente" id="border-header" class="w-screen h-[51.16px] z-auto "> -->
  </div>
</template>

