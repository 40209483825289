import { carregarAcoes } from "@/modules/acao/composables/carregarAcoes";


const stages = `
<div class="flex mb-2 mx-10 items-center">
  <svg class="mr-5" xmlns="http://www.w3.org/2000/svg" width="22.096" height="18.398" viewBox="0 0 22.096 18.398">
    <g id="Camada_x0020_1" transform="translate(0.5 0.5)">
    <rect id="Retângulo_75" data-name="Retângulo 75" width="21.096" height="4.74" rx="0.18" transform="translate(0 12.658)" fill="#feeee2" stroke="#feeee2" stroke-miterlimit="22.926" stroke-width="1"/>
    <rect id="Retângulo_76" data-name="Retângulo 76" width="21.096" height="4.74" rx="0.18" transform="translate(0 6.254)" fill="none" stroke="#feeee2" stroke-miterlimit="22.926" stroke-width="1"/>
    <rect id="Retângulo_77" data-name="Retângulo 77" width="21.096" height="4.74" rx="0.18" transform="translate(0 0)" fill="none" stroke="#feeee2" stroke-miterlimit="22.926" stroke-width="1"/>
    </g>
  </svg>
  <strong> ação fácil </strong><br/>
</div>
<div class="flex mb-2 mx-10 items-center">
  <svg class="mr-5" xmlns="http://www.w3.org/2000/svg" width="22.096" height="18.398" viewBox="0 0 22.096 18.398">
    <g id="Camada_x0020_1" transform="translate(0.5 0.5)">
    <rect id="Retângulo_75" data-name="Retângulo 75" width="21.096" height="4.74" rx="0.18" transform="translate(0 12.658)" fill="#feeee2" stroke="#feeee2" stroke-miterlimit="22.926" stroke-width="1"/>
    <rect id="Retângulo_76" data-name="Retângulo 76" width="21.096" height="4.74" rx="0.18" transform="translate(0 6.254)" fill="#feeee2" stroke="#feeee2" stroke-miterlimit="22.926" stroke-width="1"/>
    <rect id="Retângulo_77" data-name="Retângulo 77" width="21.096" height="4.74" rx="0.18" transform="translate(0 0)" fill="none" stroke="#feeee2" stroke-miterlimit="22.926" stroke-width="1"/>
    </g>
  </svg>
  <strong> ação intermediária </strong><br/>
</div>
<div class="flex mb-2 mx-10 items-center">
  <svg class="mr-5" xmlns="http://www.w3.org/2000/svg" width="22.096" height="18.398" viewBox="0 0 22.096 18.398">
    <g id="Camada_x0020_1" transform="translate(0.5 0.5)">
    <rect id="Retângulo_75" data-name="Retângulo 75" width="21.096" height="4.74" rx="0.18" transform="translate(0 12.658)" fill="#feeee2" stroke="#feeee2" stroke-miterlimit="22.926" stroke-width="1"/>
    <rect id="Retângulo_76" data-name="Retângulo 76" width="21.096" height="4.74" rx="0.18" transform="translate(0 6.254)" fill="#feeee2" stroke="#feeee2" stroke-miterlimit="22.926" stroke-width="1"/>
    <rect id="Retângulo_77" data-name="Retângulo 77" width="21.096" height="4.74" rx="0.18" transform="translate(0 0)" fill="#feeee2" stroke="#feeee2" stroke-miterlimit="22.926" stroke-width="1"/>
    </g>
  </svg>
  <strong> ação difícil </strong>
</div>
 `;
export default function (actionTour) {
  return [
    {
      id: 1,
      tour: actionTour,
      options: {
        text: "<strong> Toque para escolher sua ação de bem-estar para hoje.</strong>.",
        attachTo: { on: "top" },
        buttons: [
          {
            text: "pular",
            action: actionTour.cancel,
          },
          {
            text: "próximo",
            action: actionTour.next,
          },
        ],
      },
    },
    {
      id: 2,
      tour: actionTour,
      options: {
        when: {
          show: function () {
            carregarAcoes();
          },
        },
        text: "<strong> Leia as ações disponíveis, elas são separadas por grau de dificuldade. </strong>.",
        attachTo: { on: "top" },
        buttons: [
          {
            text: "pular",
            action: actionTour.cancel,
          },
          {
            text: "próximo",
            action: actionTour.next,
          },
        ],
      },
    },
    {
      id: 3,
      tour: actionTour,
      options: {
        text: stages,
        attachTo: { on: "top" },
        buttons: [
          {
            text: "pular",
            action: actionTour.cancel,
          },
          {
            text: "próximo",
            action: actionTour.next,
          },
        ],
      },
    },
    {
      id: 4,
      tour: actionTour,
      options: {
        text: "<strong> Se desejar, personalize uma ação, seja ela sugerida por nós ou uma prática própria. </strong>.",
        attachTo: { on: "bottom" },
        buttons: [
          {
            text: "pular",
            action: actionTour.cancel,
          },
          {
            text: "próximo",
            action: actionTour.next,
          },
        ],
      },
    },
    {
      id: 5,
      tour: actionTour,
      options: {
        text: "<strong> Agora escolha uma das ação da lista. </strong>",
        attachTo: { on: "" },
        buttons: [
          {
            text: "OK",
            action: actionTour.next,
          },
        ],
      },
    },
    {
      id: 6,
      tour: actionTour,
      options: {
        text: "<strong> Pratique a ação e observe como corpo e mente reagem.. </strong>",
        attachTo: { on: "top" },
        buttons: [
          {
            text: "pular",
            action: actionTour.cancel,
          },
          {
            text: "próximo",
            action: actionTour.next,
          },
        ],
      },
    },
  ];
}

//
