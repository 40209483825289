import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

import App from './App.vue';
import router from './router';
import { plugin, defaultConfig } from "@formkit/vue";
import PrimeVue from "primevue/config";
import 'primeicons/primeicons.css';
import '@/registerServiceWorker';
import '@/assets/tailwind.css';
import '@/assets/global.css';

import VueShepherd from 'vue-shepherd';
import 'shepherd.js/dist/css/shepherd.css';

// Connect API
import VueAxios from "vue-axios";
import axios from "@/utilities/axios";

//Modal Configs
import { createVfm } from "vue-final-modal";
import "vue-final-modal/style.css";
const vfm = createVfm();

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App);
app.use(pinia)
app.use(router);
app.use(vfm);
app.use(plugin, defaultConfig);
app.use(PrimeVue, {
    unstyled: true,
});


// Axios Connection
app.use(VueAxios, axios)
app.config.globalProperties.$axios = axios;
app.provide('axios', axios)
app.use(VueShepherd);

//Tour (shepherd)
import Vue3DirectiveShepherd from "vue3-directive-shepherd";
import { offset } from "@floating-ui/dom";
import "shepherd.js/dist/css/shepherd.css";
const options = {
  router,
  tourMap: {
    initialTour: {
      useModalOverlay: true,
      defaultStepOptions: {
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20, crossAxis: 10 })],
        },
        cancelIcon: { enabled: true },
      },
    },
    actionTour: {
      useModalOverlay: true,
      defaultStepOptions: {
        classes: "custom-shepherd-step",
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20, crossAxis: 10 })],
        },
        cancelIcon: { enabled: true },
      },
    },
  },
};
app.use(Vue3DirectiveShepherd, options);




app.mount('#app')
