<template>
<div class="content mt-[29px]">
    <h1 class=" font-bold-segoe text-center text-[26px] text-[#F37335] leading-none mx-[58px]">
        Oba! Bora praticar uma ação de <br /> bem-estar agora!
    </h1>
    <div class="logo-step flex items-center justify-center my-4">
        <img src="@/assets/icons/avatars/step-action-one.png" alt="">
        <img src="@/assets/icons/numbers/1.png" alt="">
    </div>

    <div v-show="!acoesCarregadas">
        <h2 class="font-bold-segoe text-[#F37335] text-[23px] text-center uppercase">Passo 1</h2>
        <p class="text-center font-bold-segoe text-[#F37335] mb-5 mx-[58px]">
            Escolha qual das 3 AÇÕES de bem-estar você irá realizar hoje.
        </p>
        <p class="font-italic-segoe text-[13px] text-[#727376] text-center mx-[58px]">
            Cada ação escolhida é uma oportunidade de descobrir
            como é o seu funcionamento automático diante das
            situações do dia a dia.
        </p>
        <div class="flex flex-col items-center justify-center">
            <button @click="carregarAcoes"
                class="flex items-center justify-center font-bold-segoe bg-[#F37335] outline-none border-none rounded-md text-[#FFF] text-[14px] p-[15px] my-8 mx-[27px] w-[80%] mb-[80px]"
                v-tour-step:1="steps[0]">
                escolha a ação
            </button>
        </div>
    </div>
    <ListaAcoes v-show="acoesCarregadas" :acoes="acoesList" v-tour-step:2="steps[1]" v-tour-step:3="steps[2]" />
</div>
</template>

<script setup>
import { inject, onMounted } from 'vue';
import ListaAcoes from '@/modules/acao/components/ListaAcoes.vue';
import { acoesCarregadas, acoesList, carregarAcoes } from '@/modules/acao/composables/carregarAcoes';
import createTourSteps from "@/utilities/custom-tour/actionTour/action-tour";

const actionTour = inject("actionTour");
const steps = createTourSteps(actionTour);


onMounted(() => {
    // actionTour.start();  ----> deve iniciar somente após o initialTour
})

</script>
