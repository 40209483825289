<template>
  <div class="password-input w-[271px] mb-[38px] flex bg-[#F9E1BD] text-[#F37335] p-3 rounded-xl">
    <label for="password" class="font-bold-segoe mr-2">senha</label>
    <input :type="showPassword ? 'text' : 'password'" required autocomplete="off" name="password" v-model="password"
      placeholder="Digite sua senha" class="font-normal bg-transparent placeholder-[#F37335] outline-none">
    <button @click="togglePasswordVisibility" type="button">
      <img v-if="showPassword" :src="closedEyeIcon" alt="Mostrar Senha">
      <img v-else :src="openEyeIcon" alt="Esconder Senha">
    </button>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
  modelValue: String
});
const emit = defineEmits(['update:modelValue']);

const password = ref(props.modelValue);
const showPassword = ref(false);

const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value;
};

const closedEyeIcon = require('@/assets/icons/utilities/eye-closed-orange.svg');
const openEyeIcon = require('@/assets/icons/utilities/eye-orange.svg');

watch(password, (newValue) => {
  emit('update:modelValue', newValue);
});
</script>

<style scoped>
.password-input img {
  cursor: pointer;
}
</style>
