import { ref, onMounted } from "vue";
import { useQuestionResponse } from "@/store/index";
import { useRouter } from "vue-router";

export const useQuestionForm = (
    question,
    formId,
    nextRoute,
    shouldRequestApi = false
) => {
    const store = useQuestionResponse();
    const response = ref("");
    const show = ref(false);
    const contentModal = ref("");
    const router = useRouter();
    const responseForm = {
        id: formId,
        question,
    };

    const fetchExistingResponse = () => {
        const existingResponse = store.responseQuestion.find(
            (item) => item.id === responseForm.id
        );
        if (existingResponse) {
            response.value = existingResponse.response;
        }
    };

    const requestApi = async (form) => {
        store.addQuestion(form);
        await store.requestResponse();
    };

    const submitForm = async () => {
        try {
            responseForm.response = response.value;

            if (shouldRequestApi) {
                await requestApi(responseForm);
                show.value = true;
                contentModal.value =
                    "Parabéns, mais um ciclo concluído! Pausar e refletir causa transformações.";
            }
            else {
                store.addQuestion(responseForm);
                router.push(nextRoute);
            }
        }
        catch (error) {
            console.log("ERROR: ", error);
        }
    };

    onMounted(fetchExistingResponse);

    return {
        response,
        submitForm,
        show,
        contentModal,
        store
    };
};
