<template>
  <div class="content mt-[29px]">
    <h1 class="font-bold-segoe text-center text-[26px] text-[#37A79F] leading-none mx-[58px]">
      Perguntas para <br /> ir Além
    </h1>
    <div class="logo-step flex items-center justify-center my-14">
      <img src="@/assets/icons/avatars/ask_to_go.png" alt="">
    </div>
    <div class="my-8">
      <p class="text-center font-bold-segoe text-[#37A79F] mb-5 mx-[58px]">
        Uma pergunta sempre expande suas
        possibilidades em qualquer contexto
        e situação.
      </p>
      <p class="font-italic-segoe text-[13px] text-[#727376] text-center mx-[58px]">
        Está enfrentando um dilema nesse momento?! Clique no
        botão “expandir” e receba uma pergunta que pode ajudar
        você a expandir suas possibilidades.
      </p>
    </div>
    <div class="flex flex-col items-center justify-center">
      <router-link to="/reflexao"
        class="flex items-center justify-center font-bold-segoe  bg-[#37A79F] outline-none border-none rounded-md text-[#FFF] text-[14px] p-[15px] my-8 mx-[27px] w-[80%] mb-[20px]">
        expandir
      </router-link>
    </div>
  </div>

</template>
<script setup>



</script>
<style></style>