<script setup>
import Navbar from '@/components/Navbar/Navbar.vue';
import BannerInicial from '@/components/Banners/BannerInicial.vue';
import Footer from '@/components/Footers/FooterInicial.vue';

const red = 'red';

</script>
<template>
<div class="flex flex-col min-h-screen">
    <header>
        <Navbar />
        <BannerInicial :logoHooy="red" :borderColor="red" />
    </header>
    <main class="flex-grow">
        <router-view />
    </main>
    <footer class="mt-auto">
        <Footer :iconVoltar="red" :miniLogo="red" />
    </footer>
</div>
</template>
