<script setup>
import Header from '@/components/Header/Header.vue';
import FooterInicial from '@/components/Footers/FooterInicial.vue';
import FormSignup from '@/modules/cadastro/components/FormSignup.vue';
import Loader from '@/modules/cadastro/components/Loader.vue';

import { ref } from 'vue';

const isLoading = ref(false);

const handleLoading = (loading) => {
  isLoading.value = loading;
};
</script>
<template>
  <Loader v-if="isLoading" />
  <div class="flex flex-col min-h-screen">
    <header>
      <Header logo="only-green" border="green-ag" />
    </header>
    <main class="flex-grow">
      <FormSignup @loading="handleLoading" />
    </main>
    <footer class="mt-auto">
      <FooterInicial iconVoltar="green" miniLogo="green" />
    </footer>
  </div>
</template>
